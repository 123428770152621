import { useEffect, useState } from 'react';
import Forms from '../../../../../Template/layoutComponents/Forms';
import { useTranslation } from 'react-i18next';
import { Button, notification } from 'antd';

import useTemplateState from '../../../../../Template/atoms/variables';
import { getAssetCategories, getCategories } from '../../../../helpers/apiCalls';
import { useMsal } from '@azure/msal-react';
import i18n from '../../../../../_shared/translate/translate-config';
import { getExecutors } from '../../../../../Settings/helpers/apiCalls';

const AdvancedFiltersModal = ({ advancedFilters, setAdvancedFilters }) => {
  const { t } = useTranslation();
  const { instance } = useMsal();
  const [notificationApi, contextHolder] = notification.useNotification();
  const { setModalOpen, setModalContent, setPendingApiCalls } = useTemplateState();

  const [interconnectionCategories, setInterconnectionCategories] = useState([]);

  const [categories, setCategories] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [executors, setExecutors] = useState([]);
  const [chosenItem, setChosenItem] = useState({
    properties: [
      {
        label: t('category'),
        type: 'singleSelect',
        value: null,
        options: categories,
        main: true,
        key: 'category',
      },
      {
        label: t('task'),
        type: 'singleSelect',
        value: null,
        options: tasks,
        main: true,
        key: 'task',
      },
      {
        label: t('executor'),
        type: 'singleSelect',
        value: null,
        options: executors,
        main: true,
        required: true,
        key: 'executor',
      },
      {
        label: t('start_date'),
        type: 'date',
        value: null,
        main: true,
        required: true,
        key: 'start_date',
      },
      {
        label: t('end_date'),
        type: 'date',
        value: null,
        main: true,
        required: true,
        key: 'end_date',
      },
    ],
  });

  useEffect(() => {
    triggerApiCalls();
  }, []);

  useEffect(() => {
    setTasks(
      categories
        .reduce((acc, cat) => {
          acc = acc.concat(
            cat?.commissioning?.phases?.reduce((stages, phase) => {
              stages = stages.concat(
                phase?.stages?.map((stage) => ({
                  id: stage?._id,
                  label: `${stage?.[i18n.language]?.title} (${cat?.label})`,
                })),
              );
              return stages;
            }, []),
          );

          return acc;
        }, [])
        .filter((item, pos, self) => self.indexOf(item) == pos),
    );
  }, [categories]);

  useEffect(() => {
    setChosenItem({
      properties: [
        {
          label: t('category'),
          type: 'singleSelect',
          value: advancedFilters?.['subject.category']
            ? { id: advancedFilters?.['subject.category'] }
            : null,
          options: categories,
          main: true,
          key: 'category',
        },
        {
          label: t('task'),
          type: 'singleSelect',
          value: advancedFilters?.['test'] ? { id: advancedFilters?.['test'] } : null,
          options: tasks,
          main: true,
          key: 'task',
        },
        {
          label: t('executor'),
          type: 'singleSelect',
          value: advancedFilters?.['employee'] ? { id: advancedFilters?.['employee'] } : null,
          options: executors,
          main: true,
          required: true,
          key: 'executor',
        },
        {
          label: t('start_date'),
          type: 'date',
          value: advancedFilters?.['updatedAt[gte]'] ?? null,
          main: true,
          required: true,
          key: 'start_date',
        },
        {
          label: t('end_date'),
          type: 'date',
          value: advancedFilters?.['updatedAt[lte]'] ?? null,
          main: true,
          required: true,
          key: 'end_date',
        },
      ],
    });
  }, [advancedFilters, categories, tasks, executors]);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['getCategories', 'getAssetCategories']));

      const responses = await Promise.all([
        getCategories(instance),
        getAssetCategories(instance),
        getExecutors(instance),
      ]);

      setCategories([...(responses[0] ?? []), ...(responses[1]?.items ?? [])]);

      setInterconnectionCategories(responses[0]);

      setExecutors(responses[2]?.items?.map((e) => ({ ...e, id: e._id, label: e.name })));
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
      console.log(err);
    } finally {
      setPendingApiCalls((current) =>
        current?.filter((item) => item !== 'getCategories' && item !== 'getAssetCategories'),
      );
    }
  };

  const updateCurrentEditingItem = (current, inputIndex, event) => {
    return {
      ...current,
      properties: [
        ...current.properties.map((item, index) => {
          if (index === inputIndex) {
            return {
              ...item,
              value: item.type.toLowerCase().includes('select')
                ? Array.isArray(event.target.value)
                  ? event.target.value.map((value) => ({
                      id: value,
                      label: '',
                    }))
                  : { id: event.target.value, label: '' }
                : item.type.toLowerCase().includes('bool')
                  ? event.target.value
                  : event.target.value,
            };
          }
          return item;
        }),
      ],
    };
  };

  const extractFromProperties = (searchedKey) => {
    const found = chosenItem.properties.find((prop) => prop.key === searchedKey);

    return found ? (found?.value?.id ?? found.value) : null;
  };

  return (
    <div className='advancedFiltersModal'>
      {contextHolder}
      <div
        className='formsContainer'
        style={{
          minHeight: '10rem',
          display: 'flex',
          gap: '4rem',
          flexWrap: 'wrap',
        }}
      >
        <Forms
          inputs={chosenItem.properties.slice(0, 3)}
          onInputChange={(event, inputIndex) => {
            setChosenItem((current) => {
              if (current) {
                return updateCurrentEditingItem(current, inputIndex, event);
              }
              return current;
            });
          }}
        />
        <Forms
          inputs={chosenItem.properties.slice(3)}
          onInputChange={(event, inputIndex) => {
            setChosenItem((current) => {
              if (current) {
                return updateCurrentEditingItem(current, inputIndex + 3, event);
              }
              return current;
            });
          }}
        />
      </div>
      <div className='uploadSheetModalButtons' style={{ justifyContent: 'space-between' }}>
        <Button
          className='closeModal'
          size='large'
          style={{
            borderColor: 'black',
            background: 'white',
            color: 'black',
          }}
          onClick={async () => {
            setModalContent(null);
            setModalOpen(false);
            setAdvancedFilters({});
            setChosenItem();
          }}
        >
          {Object.keys(advancedFilters)?.length > 0 ? t('clean') : t('cancel-btn')}
        </Button>
        <Button
          className='nextButton'
          size='large'
          style={{
            width: 'fit-content',
            alignSelf: 'end',
            borderColor: '#1777FF',
            background: '#1777FF',
            color: 'white',
          }}
          onClick={async () => {
            const startDate = extractFromProperties('start_date');
            const endDate = extractFromProperties('end_date');

            const chosenCategory = extractFromProperties('category');

            setAdvancedFilters({
              'subject._type': chosenCategory
                ? interconnectionCategories.some((c) => c.id === chosenCategory)
                  ? 'Interconnection'
                  : 'Asset'
                : undefined,
              'subject.category': chosenCategory,
              test: extractFromProperties('task'),
              employee: extractFromProperties('executor'),
              'updatedAt[gte]': startDate && startDate?.length > 0 ? startDate : undefined,
              'updatedAt[lte]': endDate && endDate?.length > 0 ? endDate : undefined,
            });
            setModalContent(null);
            setModalOpen(false);
          }}
        >
          {t('apply_filters')}
        </Button>
      </div>
    </div>
  );
};

export default AdvancedFiltersModal;
