import Input from '../../../../../../../../../../../Template/layoutComponents/Input';
import Select from '../../../../../../../../../../../Template/layoutComponents/SingleSelect';
import { CloseCircleOutlined } from '@ant-design/icons';

const Table = ({ title, content, editing, onValueChange }) => {
  return (
    <div className='report-table'>
      <div className='report-table-title'>
        <p
          style={{
            margin: '0 0 0 10px',
            color: 'white',
            fontWeight: '500',
          }}
        >
          {title}
        </p>
      </div>

      <table
        style={{
          width: '100%',
          borderCollapse: 'collapse',
          border: '1px solid #ddd',
        }}
      >
        <tbody>
          {Array.from({ length: Math.ceil(content.length / 2) }).map((_, rowIndex) => (
            <tr key={rowIndex}>
              {content.slice(rowIndex * 2, rowIndex * 2 + 2).map((info, colIndex) => (
                <>
                  <td
                    key={`title-${rowIndex}-${colIndex}`}
                    style={{
                      padding: '8px',
                      color: '#225968',
                      fontWeight: '500',
                      width: '15%',
                      border: '1px solid #ddd',
                    }}
                  >
                    {info.title}
                  </td>
                  <td
                    key={`value-${rowIndex}-${colIndex}`}
                    style={{
                      padding: '8px',
                      color: 'black',
                      fontWeight: '500',
                      width: '35%',
                      border: '1px solid #ddd',
                    }}
                  >
                    {editing ? (
                      info.type === 'select' ? (
                        <Select
                          value={{ id: info.value }}
                          onChange={(value) => {
                            onValueChange(info.index, value.target.value);
                          }}
                          style={{ width: '100%' }}
                          options={info.choices.map((choice) => ({
                            id: choice,
                            label: choice,
                          }))}
                        />
                      ) : typeof info.value === 'object' ? (
                        <div style={{ display: 'flex', gap: '.5vw' }}>
                          {Object.entries(info.value).map(([key, field]) => {
                            if (field.type === 'select')
                              return (
                                <Select
                                  value={{ id: field.value }}
                                  onChange={(value) => {
                                    onValueChange(info.index, value.target.value, key);
                                  }}
                                  options={field.choices}
                                />
                              );
                            return (
                              <Input
                                value={field.value}
                                onChange={(e) => onValueChange(info.index, e.target.value, key)}
                              />
                            );
                          })}
                        </div>
                      ) : (
                        <Input
                          value={info.value}
                          onChange={(e) => onValueChange(info.index, e.target.value, info.key)}
                          style={{ width: '100%' }}
                        />
                      )
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          gap: '.5vw',
                          color: info.valid ? 'black' : '#FF4D4F',
                        }}
                      >
                        {typeof info.value === 'object'
                          ? Object.values(info.value)
                              .map((value) => value.value)
                              .join(' ')
                          : info.value}
                        {!info.valid && <CloseCircleOutlined style={{ color: '#FF4D4F' }} />}
                      </div>
                    )}
                  </td>
                </>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
