import { useEffect, useState } from 'react';
import './style.css';

const PageWithScrollableSider = ({
  siderItems = [<div></div>],
  onItemClick,
  pageContent,
  defaultSelectedItem,
}) => {
  const [selectedItemIndex, setSelectedItemIndex] = useState(defaultSelectedItem ?? null);

  useEffect(() => {
    if (onItemClick) {
      onItemClick(siderItems[selectedItemIndex], selectedItemIndex);
    }
  }, [siderItems, selectedItemIndex]);

  return (
    <div className='pageWithScrollableSider'>
      <div className='scrollableSider'>
        {siderItems.map((item, index) => (
          <div
            onClick={() => {
              setSelectedItemIndex(index);
            }}
          >
            {item}
          </div>
        ))}
      </div>
      <div className='pageContent'>{pageContent}</div>
    </div>
  );
};

export default PageWithScrollableSider;
