import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import arrowDown from '../../images/arrowDown.svg';
import './style.css';
import { useTranslation } from 'react-i18next';

const MultiSelect = ({ options, value, style, onChange, disabled }) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const [dropdownStyles, setDropdownStyles] = useState({
    top: 0,
    left: 0,
    width: 0,
  });

  const dropdownRef = useRef(null);
  const listRef = useRef(null);

  useEffect(() => {
    const found = value
      ?.filter((v, index) => value.findIndex((item) => item.id === v.id) == index)
      .map((v) => options.find((opt) => opt?.id === v?.id)?.id)
      .filter((v) => v);

    setSelected(value && found.length > 0 ? found : []);
  }, [options]);

  const handleMultiSelectBehaviour = (value) => {
    let tmp = [...selected];

    if (tmp.includes(value)) {
      tmp = tmp.filter((c) => c != value);
    } else {
      tmp.push(value);
    }

    setSelected(tmp);

    onChange({ target: { value: tmp } });
    return tmp;
  };

  const handleClickOutside = (e) => {
    if (
      listRef.current &&
      !listRef.current.contains(e.target) &&
      !dropdownRef.current.contains(e.target)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleFocus = () => {
    if (!disabled) {
      const rect = dropdownRef.current.getBoundingClientRect();
      const top = rect.bottom + window.scrollY;
      const left = rect.left + window.scrollX;

      setDropdownStyles({
        top,
        left,
        width: rect.width,
      });

      setIsOpen(true);
    }
  };

  return (
    <div className='inputContainer'>
      <div className='select-container' tabIndex={0} onFocus={handleFocus} ref={dropdownRef}>
        <div className='inputIconContainer' style={{ right: 0 }}>
          <img src={arrowDown} style={{ backgroundColor: 'white' }} />
        </div>
        <div className={`customInput ${disabled ? 'disabled' : ''}`}>
          <div style={{ height: '100%', width: '90%', overflow: 'hidden' }}>
            {selected.length > 0
              ? selected
                  .map((op) => options.find((option) => option.id === op)?.label)
                  .filter((op) => op)
                  .join(', ')
              : t('choose')}
          </div>
        </div>
        {isOpen &&
          createPortal(
            <ul
              ref={listRef}
              className='dropdown-list'
              style={{
                position: 'absolute',
                top: `${dropdownStyles.top}px`,
                left: `${dropdownStyles.left}px`,
                width: `${dropdownStyles.width}px`,
              }}
            >
              {options.map((option, index) => (
                <li
                  key={index}
                  className={selected.includes(option.id) ? 'selected' : ''}
                  onClick={() => handleMultiSelectBehaviour(option.id)}
                >
                  {option.label}
                </li>
              ))}
            </ul>,
            document.body,
          )}
      </div>
    </div>
  );
};

export default MultiSelect;
