import { useNavigate, useParams } from 'react-router-dom';
import { notification } from 'antd';
import { useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';

import './style.css';
import { getStageTasks, getWoById } from '../../../../helpers/apiCalls';
import useTemplateState from '../../../../../Template/atoms/variables';
import { useEffect } from 'react';
import useMaintenanceState from '../../../../atoms/variables';
import Input from '../../../../../Template/layoutComponents/Input';
import { parseIsoDateString } from '../../../../../helpers/misc';

import TaskReports from '../../Details/components/CommissioningTab/CriteriaTypes/TaskReports/index';

const WODetails = () => {
  const { t } = useTranslation();
  const { woId } = useParams();
  const { instance } = useMsal();
  const [notificationApi, contextHolder] = notification.useNotification();

  const { setPendingApiCalls } = useTemplateState();
  const { setViewDetailsItem, viewDetailsItem } = useMaintenanceState();

  useEffect(() => {
    if (!viewDetailsItem || !viewDetailsItem.token) triggerApiCalls();
  }, []);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['getWoById']));

      const response = await getWoById(instance, woId);
      setViewDetailsItem(response);
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
    } finally {
      setPendingApiCalls((current) => current.filter((item) => item !== 'getWoById'));
    }
  };

  return (
    <div id='woDetails'>
      {contextHolder}
      <div className='pageContentBlock'>
        <div className='pageTitle'>{`${t('work_order')} #${viewDetailsItem?.token ?? ''}`}</div>
        <div className='blockContent'>
          <div className='detailsRow'>
            <div className='formsBody'>
              <div className='formsInputContainer'>
                <div className='formsInputLabel'>{t('contractor')}:</div>
                <div className='formsInput'>
                  <Input
                    disabled={true}
                    value={
                      viewDetailsItem?.employee?.properties?.find((prop) => prop.key === 'company')
                        ?.value
                    }
                  />
                </div>
              </div>
              <div className='formsInputContainer'>
                <div className='formsInputLabel'>{t('scheduled_date')}:</div>
                <div className='formsInput'>
                  <Input
                    disabled={true}
                    value={parseIsoDateString(viewDetailsItem?.scheduled_date ?? '')}
                  />
                </div>
              </div>
            </div>
            <div className='formsBody'>
              <div className='formsInputContainer'>
                <div className='formsInputLabel'>{t('executor')}:</div>
                <div className='formsInput'>
                  <Input
                    disabled={true}
                    value={
                      viewDetailsItem?.employee?.properties?.find((prop) => prop.key === 'name')
                        ?.value
                    }
                  />
                </div>
              </div>
              <div className='formsInputContainer'>
                <div className='formsInputLabel'>{t('status')}:</div>
                <div className='formsInput'>
                  <Input disabled={true} value={viewDetailsItem?.status} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='pageContentBlock' style={{ gap: 0 }}>
        <div className='pageTitle'>{t('tasks')}</div>
        {viewDetailsItem && (
          <TaskReports
            showNameOnTable={true}
            customGetParams={{
              token: viewDetailsItem?.token,
            }}
            setAllTasks={() => {}}
          />
        )}
      </div>
    </div>
  );
};

export default WODetails;
