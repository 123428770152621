import { useEffect, useState } from 'react';
import { EditOutlined } from '@ant-design/icons';

import ShieldIconComponent from '../Icons/ShieldIcon';
import AdmIcon from '../Icons/AdmIcon';
import MaintenanceIconComponent from '../Icons/MaintenanceIcon';
import HelmetIcon from '../Icons/HelmetIcon';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import useSettingsState from '../../../../../Settings/atoms/variables';
import useMaintenanceState from '../../../../../Maintenance/atoms/variables';

import ArrowIconComponent from '../Icons/ArrowIcon';
import PlantsIconComponent from '../Icons/PlantsIcon';
import AllAssetsIconComponent from '../Icons/AllAssetsIcon';
import InstrumentsIconComponent from '../Icons/InstrumentsIcon';
import AssetsIconComponent from '../Icons/AssetsIcon';
import InterconnectionsIconComponent from '../Icons/InterconnectionsIcon';
import InventoryIconComponent from '../Icons/InventoryIcon';
import ManufacturerIconComponent from '../Icons/ManufacturerIcon';

import SystemsIconComponent from '../Icons/SystemsIcon';
import WorkOrdersIconComponent from '../Icons/WorkOrdersIcon';
import OrganizationsIconComponent from '../Icons/OrganizationsIcon';
import DepartmentsIconComponent from '../Icons/DepartmentsIcon';
import CostCentersIconComponent from '../Icons/CostCentersIcon';
import AssetTreeIconComponent from '../Icons/AssetTreeIcon';
import UserGroupsIconComponent from '../Icons/UserGroupsIcon';
import ReportsIcon from '../Icons/ReportsIcon';
import UsersIconComponent from '../Icons/UsersIcon';
import { useTranslation } from 'react-i18next';

import useTemplateState from '../../../../atoms/variables';
import ExecutorIconComponent from '../Icons/ExecutorIcon';
import AppstoreIconComponent from '../Icons/AppstoreOutlined';
import './style.css';

const SiderMenu = ({ collapsed, setCollapsed, isMobile }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { chosenPositions } = useSettingsState();
  const { chosenPlant } = useMaintenanceState();
  const { companyInfo, user } = useTemplateState();
  const [forceClosed, setForceClosed] = useState([]);

  const { assetId } = useParams();

  useEffect(() => {
    handleResizeWindow();
    window.addEventListener('resize', handleResizeWindow);
    return () => {
      window.removeEventListener('resize', handleResizeWindow);
    };
  }, []);

  const handleResizeWindow = () => {
    if (window.screen.width <= 980) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  };

  const checkModuleAccess = (access, item) => {
    if (item.accessLevel === 'default') {
      return true;
    } else return access.includes(item.accessLevel);
  };

  const handleMenuClick = (path) => {
    if (location.pathname === path && !forceClosed.includes(path)) {
      if (path != '/maintenance/plants') setForceClosed((current) => current.concat([path]));
    } else if (location.pathname.includes(path) && !forceClosed.includes(path)) {
      if (path != '/maintenance/plants') setForceClosed((current) => current.concat([path]));
      navigate(path);
    } else {
      setForceClosed((current) => current.filter((item) => item !== path));
      navigate(path);
    }
  };

  return (
    <div className={`siderMenu ${isMobile ? 'mobile' : ''}`}>
      {companyInfo ? (
        [
          {
            key: '/maintenance',
            label: t('maintenance'),
            accessLevel: companyInfo?.defaultAccess?.includes('/maintenance') ? 'default' : 'admin',
            icon: (
              <MaintenanceIconComponent
                height='1.2rem'
                selected={location.pathname.includes('maintenance')}
              />
            ),
            child: [
              {
                key: '/plants',
                label: t('plants'),
                icon: <PlantsIconComponent height='1.2rem' />,
                child: [
                  {
                    disabled: !chosenPlant,
                    key: `${chosenPlant ? `/${chosenPlant.id}` : '/'}`,
                    label: t('assets'),
                    keepSelected: assetId,
                    icon: <AssetsIconComponent height='1.2rem' />,
                  },
                  {
                    disabled: !chosenPlant || chosenPlant.id === 'newPlant',
                    key: `${chosenPlant ? `/${chosenPlant.id}` : ''}/interconnections`,
                    fakeKey: `${chosenPlant ? `/${chosenPlant.id}` : ''}/interconnections`,
                    label: t('interconnections'),
                    icon: <InterconnectionsIconComponent height='1.2rem' />,
                    child: [],
                  },
                  {
                    disabled: !chosenPlant || chosenPlant.id === 'newPlant',
                    key: `${chosenPlant ? `/${chosenPlant.id}` : ''}/systems`,
                    label: t('systems'),
                    icon: <SystemsIconComponent height='1.2rem' />,
                    child: [],
                  },
                  {
                    disabled: !chosenPlant || chosenPlant.id === 'newPlant',
                    key: `${chosenPlant ? `/${chosenPlant.id}` : ''}/work-orders`,
                    fakeKey: `${chosenPlant ? `/${chosenPlant.id}` : ''}/work-orders`,
                    label: t('work_orders'),
                    icon: <WorkOrdersIconComponent height='1.2rem' />,
                    child: [],
                  },
                  {
                    disabled: !chosenPlant || chosenPlant.id === 'newPlant',
                    key: `${chosenPlant ? `/${chosenPlant.id}` : ''}/reports`,
                    label: t('reports'),
                    icon: <ReportsIcon height='1.2rem' />,
                    child: [],
                  },
                ],
              },
              {
                key: '/assets',
                label: t('all_assets'),
                icon: <AllAssetsIconComponent height='1.2rem' />,
              },
              {
                key: '/instruments',
                label: t('instruments'),
                icon: <InstrumentsIconComponent height='1.2rem' />,
              },
              {
                key: `/executors`,
                label: t('executors'),
                icon: <ExecutorIconComponent height='1.2rem' />,
              },
            ],
          },
          {
            key: '/virtual-engineer',
            label: 'Virtual Engineer',
            accessLevel: companyInfo?.defaultAccess?.includes('/virtual-engineer')
              ? 'default'
              : 'admin',
            icon: (
              <HelmetIcon
                height='1.2rem'
                selected={location.pathname.includes('virtual-engineer')}
              />
            ),
          },
          {
            disabled: true,
            key: '/safety-copilot',
            label: 'Safety Copilot',
            accessLevel: companyInfo?.defaultAccess?.includes('/safety-copilot')
              ? 'default'
              : 'admin',
            icon: (
              <ShieldIconComponent
                height='1.2rem'
                selected={location.pathname.includes('safety-copilot')}
              />
            ),
          },
          {
            key: '/settings',
            label: t('administration'),
            accessLevel: companyInfo?.defaultAccess?.includes('/settings') ? 'default' : 'admin',
            icon: <AdmIcon height='1.2rem' selected={location.pathname.includes('settings')} />,
            child: [
              {
                disabled: false,
                key: '/manage-products',
                label: t('manage_products'),
                icon: <AppstoreIconComponent height='1.2rem' />,
                child: [
                  {
                    disabled: false,
                    key: '/virtualengineer',
                    label: t('virtual_engineer'),
                    icon: <HelmetIcon height='1.2rem' />,
                  },
                ],
              },
              {
                disabled: false,
                key: '/organizations',
                label: t('organizations'),
                icon: <OrganizationsIconComponent height='1.2rem' />,
              },
              {
                disabled: false,
                key: '/departments',
                label: t('departments'),
                icon: <DepartmentsIconComponent height='1.2rem' />,
              },
              {
                disabled: false,
                key: '/cost-centers',
                label: t('cost_centers'),
                icon: <CostCentersIconComponent height='1.2rem' />,
              },
              {
                key: '/inventory',
                label: t('inventory'),
                icon: <InventoryIconComponent height='1.2rem' />,
                child: [
                  {
                    key: '/manufacturers',
                    label: t('manufacturers'),
                    icon: <ManufacturerIconComponent height='1.2rem' />,
                    child: [],
                  },
                ],
              },
              {
                key: chosenPositions?.length > 0 ? '/asset-tree/manage' : '/asset-tree',
                label: t('asset_tree'),
                icon: <AssetTreeIconComponent height='1.2rem' />,
              },
              {
                disabled: false,
                key: '/knowledge/manage',
                label: t('manage_knowledge'),
                icon: <EditOutlined style={{ fontSize: '1.2rem' }} />,
              },
              {
                disabled: false,
                key: '/user-groups',
                label: t('user_groups'),
                icon: <UserGroupsIconComponent height='1.2rem' />,
              },
              {
                disabled: false,
                key: '/users',
                label: t('users'),
                icon: <UsersIconComponent height='1.2rem' />,
              },
            ],
          },
        ]
          .filter(
            (item) =>
              companyInfo?.modules.includes(item.key) && checkModuleAccess(user?.access, item),
          )
          .map((item, itemIndex) => (
            <>
              {!isMobile && <div className='siderDivider'></div>}
              <div className='menuList siderModule'>
                <div
                  className={`menuButton ${item.disabled ? 'disabled' : ''}  ${collapsed ? 'collapsed' : ''} ${collapsed || !item.child ? (location.pathname.includes(item.key) ? 'selected' : '') : location.pathname == item.key ? 'selected' : ''} siderModuleContent`}
                  onClick={() => {
                    if (!item.disabled) handleMenuClick(item.key);
                  }}
                >
                  {item.icon}
                  {!collapsed && item.label}
                  {!collapsed && item.child && (
                    <ArrowIconComponent
                      open={location.pathname.includes(item.key) && !forceClosed.includes(item.key)}
                    />
                  )}
                </div>

                {item?.child?.length && (
                  <div
                    id={`wrapper-${itemIndex}`}
                    className={`wrapper ${collapsed ? 'collapsed' : ''} ${isMobile ? 'mobile' : ''}`}
                    style={
                      itemIndex === companyInfo?.modules?.length - 1 && isMobile
                        ? { right: '1rem' }
                        : {}
                    }
                  >
                    <div className={`moduleContent ${collapsed ? 'collapsed' : ''} `}>
                      {(collapsed || location.pathname.includes(item.key)) &&
                        item?.child?.map((child) => (
                          <div className='menuList moduleMenu'>
                            <div
                              className={`menuButton ${child.disabled ? 'disabled' : ''} ${child.child ? (location.pathname == `${item.key}${child.key}` ? 'selected' : '') : location.pathname.includes(`${item.key}${child.key}`) ? 'selected' : ''} moduleMenuContent`}
                              onClick={() => {
                                if (!child.disabled) handleMenuClick(`${item.key}${child.key}`);
                              }}
                            >
                              {child.icon}
                              {child.label}
                              {child.child && (
                                <ArrowIconComponent
                                  open={
                                    location.pathname.includes(child.key) &&
                                    !forceClosed.includes(`${item.key}${child.key}`)
                                  }
                                />
                              )}
                            </div>
                            {location.pathname.includes(child.key) &&
                              !forceClosed.includes(`${item.key}${child.key}`) &&
                              child?.child?.map((grandson) => (
                                <div className='menuOption'>
                                  <div
                                    className={`menuButton ${grandson.disabled ? 'disabled' : ''} ${location.pathname.includes(`${item.key}${child.key}`) && location.pathname.includes(grandson.keepSelected) ? 'selected' : grandson.child ? (location.pathname.includes(`${item.key}${child.key}${grandson.fakeKey ?? grandson.key}`) ? 'selected' : '') : location.pathname === `${item.key}${child.key}${grandson.fakeKey ?? grandson.key}` ? 'selected' : ''} menuOptionContent`}
                                    onClick={() => {
                                      if (!grandson.disabled)
                                        handleMenuClick(`${item.key}${child.key}${grandson.key}`);
                                    }}
                                  >
                                    {grandson.icon}
                                    {grandson.label}
                                  </div>
                                </div>
                              ))}
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            </>
          ))
      ) : (
        <>
          {!isMobile && <div className='siderDivider'></div>}

          <div className={`menuList siderModule ${isMobile ? 'mobile' : ''}  skeleton-box`}>
            <div
              className={`menuButton siderModuleContent`}
              style={isMobile ? { display: 'flex', justifyContent: 'center', padding: 0 } : {}}
            >
              <MaintenanceIconComponent height='1.2rem' />
              {!isMobile && '. . .'}
            </div>
          </div>
          {!isMobile && <div className='siderDivider'></div>}

          <div className={`menuList siderModule ${isMobile ? 'mobile' : ''}  skeleton-box`}>
            <div
              className={`menuButton siderModuleContent`}
              style={isMobile ? { display: 'flex', justifyContent: 'center', padding: 0 } : {}}
            >
              <HelmetIcon height='1.2rem' />
              {!isMobile && '. . .'}
            </div>
          </div>
          {!isMobile && <div className='siderDivider'></div>}

          <div className={`menuList siderModule ${isMobile ? 'mobile' : ''}  skeleton-box`}>
            <div
              className={`menuButton siderModuleContent`}
              style={isMobile ? { display: 'flex', justifyContent: 'center', padding: 0 } : {}}
            >
              <ShieldIconComponent height='1.2rem' />
              {!isMobile && '. . .'}
            </div>
          </div>
          {!isMobile && <div className='siderDivider'></div>}

          <div className={`menuList siderModule skeleton-box`}>
            <div
              className={`menuButton siderModuleContent`}
              style={isMobile ? { display: 'flex', justifyContent: 'center', padding: 0 } : {}}
            >
              <AdmIcon height='1.2rem' />
              {!isMobile && '. . .'}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SiderMenu;
