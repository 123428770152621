import { Button, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { SyncOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import useTemplateState from '../../../../../../Template/atoms/variables';
import Markdown from 'react-markdown';
import { useEffect, useState } from 'react';
import { sleep } from '../../../../../../helpers/misc';
import TableModal from './TableModal';
import { regenerateKnowledge } from '../../../../../helpers/conversation';
import { useMsal } from '@azure/msal-react';

const EditModal = ({ value = '# test', prompt = 'prompt', generatorId }) => {
  const { t } = useTranslation();
  const {
    setModalOpen,
    setModalContent,
    setModalStyle,
    setCleanOnCancel,
    setModalTitle,
    setModalConfirm,
  } = useTemplateState();

  const { instance } = useMsal();

  const [newPrompt, setNewPrompt] = useState('');
  const [currentPromptIndex, setCurrentPromptIndex] = useState(0);
  const [promptHistory, setPromptHistory] = useState([
    {
      prompt: prompt,
      result: value,
    },
  ]);

  useEffect(() => {
    setPromptHistory([
      {
        prompt: prompt ?? '',
        result: value ?? '',
      },
    ]);
  }, [value, prompt]);

  useEffect(() => {
    setTmpValue(promptHistory[currentPromptIndex]?.result);
    setFinalValue(promptHistory[currentPromptIndex]?.result);
  }, [promptHistory, currentPromptIndex]);

  useEffect(() => {
    const element = document.getElementById(`request-${promptHistory.length - 1}`);
    if (element) element.scrollIntoView();
  }, [promptHistory]);

  const [tmpValue, setTmpValue] = useState(value ?? '');
  const [finalValue, setFinalValue] = useState(value ?? '');

  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);

  const handleRegenerateClick = async () => {
    try {
      setLoading(true);

      const response = await regenerateKnowledge(instance, generatorId, newPrompt);

      const newResult = response?.content;

      let historyLength = 0;
      setPromptHistory((current) => {
        const updated = current.concat([
          {
            prompt: newPrompt,
            result: newResult,
          },
        ]);
        historyLength = updated.length;

        return updated;
      });
      setCurrentPromptIndex(historyLength - 1);

      setNewPrompt('');

      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handlePromptChange = (e) => {
    setNewPrompt(e.target.value);
  };

  const handleTextAreaChange = (e) => {
    setTmpValue(e.target.value);
  };

  const handleEditClick = () => {
    setEditing(true);
  };

  const handleCancelClick = () => {
    setEditing(false);
    setTmpValue(finalValue);
  };

  const handleSaveClick = () => {
    setPromptHistory((current) =>
      current.map((entry, index) => {
        if (index === currentPromptIndex) {
          return { ...entry, result: tmpValue };
        }
        return entry;
      }),
    );
    setEditing(false);
  };

  const showTableModal = async (generatorId, prompt, result) => {
    setModalTitle(t('asset_selection_title'));
    setModalContent(<TableModal generatorId={generatorId} prompt={prompt} result={result} />);
    setModalStyle({ padding: '2rem', gap: '1rem', minHeight: '70%', minWidth: '55%' });
    setModalConfirm(null);
    setModalOpen(true);
  };

  return (
    <div className='editModal'>
      <div className='editModalColumn first'>
        <div className='editModalColumnTitle'>{t('new_request_input')} </div>
        <div className='editModalRegenerateContainer'>
          <textarea
            className='addKnowledgeTextArea'
            placeholder={t('request_placeholder')}
            value={newPrompt}
            onChange={handlePromptChange}
          />
          <Button
            size='large'
            icon={<SyncOutlined />}
            style={{
              alignSelf: 'end',
              width: 'fit-content',
              backgroundColor: '#1777FF',
              border: '#1777FF',
              color: 'white',
              opacity: editing || loading || newPrompt.length === 0 ? 0.5 : 1,
            }}
            disabled={editing || newPrompt.length === 0 || loading}
            onClick={handleRegenerateClick}
          >
            {t('generate_again')}
          </Button>
        </div>
        <div className='editModalRequestsContainer'>
          <div className='requestsTitle'>{t('requests')}</div>
          {promptHistory.map((req, index) => (
            <div
              id={`request-${index}`}
              className={`request ${index === currentPromptIndex ? 'selected' : ''} ${editing || loading ? 'disabled' : ''}`}
              onClick={() => {
                if (!editing && !loading) setCurrentPromptIndex(index);
              }}
            >
              {req.prompt}
            </div>
          ))}
        </div>
      </div>
      <div className='editModalColumn second'>
        <div className='editModalColumnTitle'>
          {t('virtual_engineer_research')}
          <div className='modalClose'>
            <CloseOutlined
              className='closeIcon'
              onClick={async () => {
                setModalOpen(false);
                setModalStyle(null);
              }}
            />
          </div>
        </div>
        {loading ? (
          <div className='addKnowledgeLoading'>
            <Spin percent={'auto'} size='large' />
            <div className='addKnowledgeLoadingText'>{t('generating_knowledge')}</div>
          </div>
        ) : (
          <>
            <div className='versionSelector'>
              <div
                className={`controll back ${currentPromptIndex === 0 ? 'disabled' : ''}`}
                onClick={() => {
                  if (currentPromptIndex !== 0) setCurrentPromptIndex((current) => current - 1);
                }}
              >
                {'<'}
              </div>
              <div className='pageNumber'>{`${currentPromptIndex + 1} / ${promptHistory.length}`}</div>
              <div
                className={`controll forward ${currentPromptIndex === promptHistory.length - 1 ? 'disabled' : ''}`}
                onClick={() => {
                  if (currentPromptIndex !== promptHistory.length - 1)
                    setCurrentPromptIndex((current) => current + 1);
                }}
              >
                {'>'}
              </div>
            </div>
            {editing ? (
              <textarea className='addKnowledgeTextArea' onChange={handleTextAreaChange}>
                {finalValue}
              </textarea>
            ) : (
              <div className='addKnowledgeTextArea md'>
                <Markdown key={currentPromptIndex}>{finalValue}</Markdown>
              </div>
            )}
            <div className='buttonsContainer'>
              {editing ? (
                <Button
                  id='cancel'
                  size='large'
                  style={{
                    backgroundColor: 'white',
                    borderColor: 'black',
                    color: 'black',
                  }}
                  onClick={handleCancelClick}
                >
                  {t('cancel-btn')}
                </Button>
              ) : (
                <Button
                  icon={<EditOutlined />}
                  size='large'
                  style={{
                    backgroundColor: '#333333',
                    border: '#333333',
                    color: 'white',
                    opacity: loading ? 0.5 : 1,
                  }}
                  onClick={handleEditClick}
                >
                  {t('edit_btn')}
                </Button>
              )}
              <Button
                size='large'
                style={{
                  backgroundColor: '#1777FF',
                  border: '#1777FF',
                  color: 'white',
                  opacity: loading || tmpValue.length === 0 ? 0.5 : 1,
                }}
                disabled={loading || tmpValue.length === 0}
                onClick={() =>
                  editing
                    ? handleSaveClick()
                    : showTableModal(
                        generatorId,
                        promptHistory[currentPromptIndex].prompt,
                        finalValue,
                      )
                }
              >
                {editing ? t('save_btn') : t('next')}
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default EditModal;
