import { notification } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import AddFilesModal from './components/AddFilesModal';
import useTemplateState from '../../atoms/variables';

const UploadDocumentPage = ({
  id,
  type = 'asset',
  getCall = async () => {
    return [];
  },
  uploadCall = async () => {},
  deleteCall = async () => {},
}) => {
  const { t } = useTranslation();
  const { setPendingApiCalls } = useTemplateState();

  const { instance } = useMsal();
  const [files, setFiles] = useState([]);
  const [notificationApi, contextHolder] = notification.useNotification();

  useEffect(() => {
    triggerApiCalls();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (files.length > 0) {
        const filesInProcessing = files.filter((file) => file?.metadata?.status === '0');
        if (filesInProcessing.length > 0) {
          triggerApiCalls();
        }
      }
    }, 120000);

    return () => clearInterval(interval);
  }, [files]);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['getAssetFiles']));
      const response = await getCall(instance, type, id, false);
      setFiles(response);
    } catch (err) {
      console.log(err);
      notificationApi.error({
        message: t('errorLoad'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
    } finally {
      setPendingApiCalls((current) => current.filter((item) => item !== 'getAssetFiles'));
    }
  };

  const deleteItem = async ({ file }) => {
    try {
      setPendingApiCalls((current) => current.concat(['deleteFile']));
      await deleteCall(instance, type, id, file?.name);
    } catch (err) {
      console.log(err);
      notificationApi.error({
        message: t('errorDelete'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
    } finally {
      setPendingApiCalls((current) => current.filter((item) => item !== 'deleteFile'));
      triggerApiCalls();
    }
  };

  return (
    <div className='UploadDocumentPage'>
      {contextHolder}
      <div className='manageFilesButtonsContainer'>
        <AddFilesModal
          id={id}
          type={type}
          uploadCall={uploadCall}
          reloadFileList={triggerApiCalls}
        />
      </div>

      <div className='documentPseudoTable'>
        <div className='documentPseudoTableLine header'>
          <div className='pseudoTableLineCell header'>{t('fileName')}</div>
          <div className='pseudoTableLineCell header'>{t('uploadDate')}</div>
          <div className='pseudoTableLineCell header'>{t('uploadedBy')}</div>
          <div className='pseudoTableLineCell header'>{t('status')}</div>
          <div className='pseudoTableLineCell header'></div>
        </div>
        {files
          .filter((f) => !f.name.includes('.pdf/images/'))
          .map((file) => {
            return (
              <div className='documentPseudoTableLine' key={file?.name}>
                <div className='pseudoTableLineCell'>
                  <a href={file.url} target='_blank'>
                    {file?.name?.match(/(?<=\/files\/).*/g)}
                  </a>
                </div>
                <div className='pseudoTableLineCell'>
                  {file?.lastModified ? moment(file.lastModified).format('DD/MM/YYYY HH:mm') : ''}
                </div>
                <div className='pseudoTableLineCell'>
                  {file?.metadata?.userid ?? t('unknownUser')}
                </div>
                <div className='pseudoTableLineCell'>
                  {file?.metadata?.status === '0'
                    ? t('processing')
                    : file?.metadata?.status === '-1'
                      ? t('error')
                      : t('completed')}
                </div>
                <div className='pseudoTableLineCell'>
                  <div
                    className='manageDots'
                    onClick={async () => {
                      await deleteItem({ file: file });
                    }}
                  >
                    <DeleteOutlined style={{ fontSize: '1.2rem' }} />
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default UploadDocumentPage;
