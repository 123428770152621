import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useMaintenanceState from '../../../atoms/variables';
import Tabs from '../../../../Template/layoutComponents/Tabs';
import ArrowSteps from '../../../components/CommissioningProgress/index';
import DocumentUpload from '../../../pages/plants/Details/components/CommissioningTab/CriteriaTypes/DocumentUpload/index';
import './style.css';
import useTemplateState from '../../../../Template/atoms/variables';
import { useTranslation } from 'react-i18next';
import UploadDocumentPage from '../../../../Template/layoutComponents/UploadDocumentPage';
import { deleteFile, getFiles, saveFile } from '../../../helpers/files';
import { updateAssetTreeItem } from '../../../helpers/apiCalls';
import GeneralTab from '../../../components/GeneralTab';
import { getAllCategories } from '../../../../Settings/helpers/apiCalls';
import CommissioningTab from './components/CommissioningTab';
import { findById } from '../../../../helpers/misc';
import WosDetailPage from '../interconnections/components/wosDetailPage';

const assetTypes = ['machine', 'assembly', 'equipment', 'component'];

const Details = () => {
  const { t } = useTranslation();

  const { companyInfo } = useTemplateState();

  const { existingAssetTrees, chosenPlant, viewDetailsItem, setViewDetailsItem } =
    useMaintenanceState();

  const [isCommissionable, setIsCommissionable] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const location = useLocation();

  const { plantId, assetId } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    if (!plantId || !assetId) {
      navigate(`/maintenance/plants/${plantId}`);
    } else if (!viewDetailsItem || !viewDetailsItem.purchase_date) {
      if (chosenPlant) setViewDetailsItem(findById(chosenPlant, assetId));
    }
  }, [location, chosenPlant]);

  useEffect(() => {
    const catProp = viewDetailsItem?.properties?.find((prop) => prop.key === 'category')?.value?.id;

    if (viewDetailsItem?.category?._id === catProp || viewDetailsItem?.category?.id === catProp) {
      setIsCommissionable(viewDetailsItem?.category?.commissionable);
    } else {
      const newlyGivenCat = allCategories?.find((cat) => cat.id === catProp);
      setIsCommissionable(newlyGivenCat?.commissionable);
    }
  }, [viewDetailsItem, allCategories]);

  function findItem(id) {
    if (existingAssetTrees) {
      let tree = existingAssetTrees.find((tree) => tree?.id === plantId);
      if (tree) {
        return findById(tree, id);
      }
    }
  }

  function updateEditingItem(setEditing, allCategories, current, inputIndex, event) {
    setEditing(true);
    let chosenCategory;
    // Search by current selected ID
    chosenCategory = allCategories?.find((cat) => {
      return cat.id === event.target.value;
    });

    if (!chosenCategory)
      // If not found, search by selected Categoy in 'Categoria' property
      chosenCategory = allCategories?.find((cat) => {
        const catProp = current.properties.find((prop) => prop?.key === 'category' && prop?.main);

        return cat.id === catProp?.value?.id;
      });

    const chosenCategoryProperties = chosenCategory ? [...chosenCategory?.properties] : [];

    let updateName = false;

    return {
      ...current,
      properties: [
        ...current.properties.map((item, index) => {
          if (index === inputIndex) {
            if (item.key === 'name') {
              updateName = event.target.value;
            }

            return {
              ...item,
              value: item.type.toLowerCase().includes('select')
                ? Array.isArray(event.target.value)
                  ? event.target.value.map((value) => ({
                      id: value,
                      label: '',
                    }))
                  : { id: event.target.value, label: '' }
                : item.type.toLowerCase().includes('bool')
                  ? event.target.value
                  : event.target.value,
              // event.target.value?.replace(/ /g, ''),
            };
          }
          return item;
        }),
      ]
        .filter(
          (prop) =>
            prop.main ||
            chosenCategoryProperties.some((currentCatProp) => currentCatProp.key == prop.key),
        )
        .concat(
          chosenCategory?.properties
            ?.filter((prop) => !prop.main)
            .filter(
              (prop) =>
                !current.properties.some(
                  (currentProp) => !currentProp.main && currentProp.key == prop.key,
                ),
            ) ?? [],
        ),
      name: updateName ? updateName : current.name,
    };
  }

  return (
    <div id='assetDetails'>
      <Tabs
        chosenTabStyleOverride={{ padding: '0 3rem 3rem 3rem', position: 'relative' }}
        tabs={[
          {
            label: t('general_tab'),
            content: (
              <GeneralTab
                update={updateAssetTreeItem}
                getCategories={getAllCategories}
                updateCurrentEditingItem={updateEditingItem}
                goBackTo={`/maintenance/plants/${plantId}`}
                setExternalAllCategories={setAllCategories}
              />
            ),
          },
          assetTypes.includes(findItem(assetId)?.type) &&
            companyInfo?.commissioning && {
              label: t('commissioning_tab'),
              content: (
                <CommissioningTab
                  steps={[
                    { label: 'L1' },
                    { label: 'L2' },
                    { label: 'L3' },
                    { label: 'L4' },
                    { label: 'L5' },
                  ]}
                />
              ),
              disabled: !isCommissionable,
            },
          assetTypes.includes(findItem(assetId)?.type) &&
            companyInfo?.commissioning && {
              label: t('work_orders_tab'),
              content: <WosDetailPage />,
              disabled: !isCommissionable,
            },
          {
            label: t('documents_tab'),
            content: (
              <div className='assetDetailsTab'>
                <UploadDocumentPage
                  id={assetId}
                  type={assetTypes.includes(findItem(assetId)?.type) ? 'asset' : 'position'}
                  getCall={getFiles}
                  uploadCall={saveFile}
                  deleteCall={deleteFile}
                />
              </div>
            ),
          },
          assetTypes.includes(findItem(assetId)?.type) && {
            label: t('chat_tab'),
            content: <div className='assetDetailsTab'></div>,
            disabled: !companyInfo.modules?.includes('/virtual-engineer'),
            onClick: () => navigate(`/virtual-engineer/equipments/${assetId}/threads/new`),
          },
        ].filter((tab) => tab)}
      />
    </div>
  );
};

export default Details;
