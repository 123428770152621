import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import useGlobalState from '../../Template/atoms/variables';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import useMaintenanceState from '../atoms/variables';
import {
  getCostCenters,
  getDepartments,
  getManufacturers,
  getOrgs,
  getSystems,
} from '../helpers/apiCalls';
import { useMsal } from '@azure/msal-react';
export const MaintenanceOutlet = () => {
  const { instance } = useMsal();
  const { setTitle, setPendingApiCalls } = useGlobalState();
  const { setOrgs, setDepartments, setSystems, setManufacturers, setCostcenters } =
    useMaintenanceState();
  const [notificationApi, contextHolder] = notification.useNotification();
  const { t } = useTranslation();
  useEffect(() => {
    setTitle(null);
    triggerApiCalls();
  }, []);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) =>
        current.concat(['getOrgs', 'getDepartments', 'getSystems', 'getManufacturers']),
      );

      const responses = await Promise.all([
        getOrgs(instance),
        getDepartments(instance),
        getSystems(instance),
        getManufacturers(instance),
        getCostCenters(instance),
      ]);
      setOrgs(responses[0]);
      setDepartments(responses[1]);
      setSystems(responses[2]);
      setManufacturers(responses[3]);
      setCostcenters(responses[4]);
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
      console.log(err);
    } finally {
      setPendingApiCalls((current) =>
        current.filter(
          (item) =>
            item !== 'getExistingAssetTrees' &&
            item !== 'getOrgs' &&
            item !== 'getDepartments' &&
            item !== 'getSystems' &&
            item !== 'getManufacturers',
        ),
      );
    }
  };

  return (
    <div className='default-module-outlet'>
      {contextHolder}
      <Outlet />
    </div>
  );
};
