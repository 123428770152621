import { useTranslation } from 'react-i18next';
const Header = ({ reportLogo, report }) => {
  const { i18n } = useTranslation();
  return (
    <div className='report-header'>
      <img src={reportLogo} className='logo-overview' />
      <div className='title'>
        <h4 style={{ margin: '0' }}>{report.test[i18n.language].reportName}</h4>
      </div>
      <div className='title'>
        <h4 style={{ margin: '0' }}>
          {report.code}-{report.subject?.location?.name ?? 'N/A'}-
          {report.sequential.toString().padStart(4, '0')}
        </h4>
      </div>
    </div>
  );
};

export default Header;
