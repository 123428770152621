import { useEffect, useState } from 'react';

import { Row, Col, Form, Select, DatePicker, Space, Card, Spin, Button } from 'antd';
import { Bar, Column, DualAxes, Line, WordCloud } from '@ant-design/charts';
import { getAnalyticsData, getCompanies } from '../../../helpers/apiCalls';
import { useMsal } from '@azure/msal-react';
import Title from 'antd/es/typography/Title';
import { useTranslation } from 'react-i18next';
import { getAssets, getPlants } from '../../../../Maintenance/helpers/apiCalls';
import useGlobalState from '../../../../Template/atoms/variables';

import emptyResponse from './emptyReponse.json';

import './style.css';
import moment from 'moment';

const { RangePicker } = DatePicker;

export default function Analytics() {
  const { t } = useTranslation();

  const { companyInfo } = useGlobalState();
  const { instance } = useMsal();

  const [plants, setPlants] = useState([]);
  const [machines, setMachines] = useState([]);

  const [analyticsData, setAnalyticsData] = useState(null);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (companyInfo) triggerApiCalls();
  }, [companyInfo]);

  const triggerApiCalls = async (date, plant, machine) => {
    try {
      setLoading(true);
      const plants = await getPlants(instance, {});
      setPlants(plants.items);

      const machines = await getAssets(instance, {});
      setMachines(machines.items);

      const data = await getAnalyticsData(instance, companyInfo.dbName, date, plant, machine);
      setAnalyticsData(data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    triggerApiCalls(
      form.getFieldValue('date'),
      form.getFieldValue('plant'),
      form.getFieldValue('machine'),
    );
  };

  const handlePlantSelect = (plantId) => {
    form.setFieldValue('machine', null);
  };

  const handleMachineSelect = (machineId) => {
    const machine = machines.find((mach) => mach._id === machineId);
    const plant = plants.find((plt) => plt._id === machine.location);
    form.setFieldValue('plant', plant._id);
  };

  return (
    <div
      style={{
        backgroundColor: '#fff',
        height: '100%',
        padding: '1%',
        overflowY: 'auto',
      }}
    >
      <Form form={form} layout='vertical' style={{ padding: '18px 18px 0 18px' }}>
        <Row className='formRow' gutter={16}>
          {/* Date Range Picker */}
          <Col span={6} xs={24} sm={12} md={8} lg={6}>
            <Form.Item
              name={'date'}
              label={<span style={{ fontWeight: 'bold' }}>{t('analytics_date_range')}</span>}
            >
              <RangePicker
                disabledDate={(current) => current && current.isAfter(moment().endOf('day'))}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>

          {/* Plant Dropdown */}
          <Col span={6} xs={24} sm={12} md={8} lg={6}>
            <Form.Item
              name={'plant'}
              label={<span style={{ fontWeight: 'bold' }}>{t('analytics_plant')}</span>}
            >
              <Select onChange={handlePlantSelect} placeholder='Select Plant'>
                {plants &&
                  plants?.map((plant) => (
                    <Select.Option value={plant._id}>{plant.name}</Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>

          {/* Machine Dropdown */}
          <Col span={6} xs={24} sm={12} md={8} lg={6}>
            <Form.Item
              name={'machine'}
              label={<span style={{ fontWeight: 'bold' }}>{t('analytics_machine')}</span>}
            >
              <Select onChange={handleMachineSelect} placeholder='All'>
                {machines &&
                  machines?.map((machine) => (
                    <Select.Option value={machine._id}>{machine.name}</Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>

          {/* Submit */}
          <Col
            span={6}
            xs={26}
            sm={16}
            md={6}
            lg={6}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Button
              onClick={handleSubmit}
              style={{
                borderColor: '#1777FF',
                background: '#1777FF',
                color: 'white',
              }}
            >
              {t('submit')}
            </Button>
          </Col>
        </Row>
      </Form>
      <div style={{ width: '100%', padding: '18px' }}>
        <Row gutter={16}>
          <Col span={6} xs={24} sm={12} md={8} lg={6}>
            <Card bordered={true}>
              <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                <p style={{ fontSize: '18px', margin: '0', fontWeight: 'bold' }}>
                  {t('analytics_user_messages')}
                </p>
                {!analyticsData || loading ? (
                  <Spin
                    size='large'
                    style={{ display: 'block', textAlign: 'center', marginTop: '50px' }}
                  />
                ) : (
                  <p style={{ fontSize: '80px', color: '#1777FF', margin: '0', padding: '0' }}>
                    {analyticsData['count_all_messages'] ?? 0}
                  </p>
                )}
              </div>
            </Card>
          </Col>
          <Col span={6} xs={24} sm={12} md={8} lg={6}>
            <Card bordered={true}>
              <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                <p style={{ fontSize: '18px', margin: '0', fontWeight: 'bold' }}>
                  {t('analytics_conversations')}
                </p>
                {!analyticsData || loading ? (
                  <Spin
                    size='large'
                    style={{ display: 'block', textAlign: 'center', marginTop: '50px' }}
                  />
                ) : (
                  <p style={{ fontSize: '80px', color: '#1777FF', margin: '0', padding: '0' }}>
                    {analyticsData['unique_conversations'] ?? 0}
                  </p>
                )}
              </div>
            </Card>
          </Col>
          <Col span={6} xs={24} sm={12} md={8} lg={6}>
            <Card bordered={true}>
              <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                <p style={{ fontSize: '18px', margin: '0', fontWeight: 'bold' }}>
                  {t('analytics_active_users')}
                </p>
                {!analyticsData || loading ? (
                  <Spin
                    size='large'
                    style={{ display: 'block', textAlign: 'center', marginTop: '50px' }}
                  />
                ) : (
                  <p style={{ fontSize: '80px', color: '#1777FF', margin: '0', padding: '0' }}>
                    {analyticsData['count_all_active_users'] ?? 0}
                  </p>
                )}
              </div>
            </Card>
          </Col>
          <Col span={6} xs={24} sm={12} md={8} lg={6}>
            <Card bordered={true}>
              <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                <p style={{ fontSize: '18px', margin: '0', fontWeight: 'bold' }}>
                  {t('analytics_total_users')}
                </p>
                {!analyticsData || loading ? (
                  <Spin
                    size='large'
                    style={{ display: 'block', textAlign: 'center', marginTop: '50px' }}
                  />
                ) : (
                  <p style={{ fontSize: '80px', color: '#1777FF', margin: '0', padding: '0' }}>
                    {analyticsData['count_total_users'] ?? 0}
                  </p>
                )}
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <div style={{ width: '100%', padding: '18px' }}>
        <Row gutter={16}>
          <Col span={12} xs={24} sm={12} md={8} lg={12} style={{ marginBottom: '16px' }}>
            <Card bordered={true}>
              <Title
                style={{
                  margin: 0,
                  textAlign: 'center',
                  fontSize: 18,
                  justifySelf: 'center',
                }}
              >
                {t('analytics_user_messages')}
              </Title>
              {!analyticsData || loading ? (
                <Spin
                  size='large'
                  style={{ display: 'block', textAlign: 'center', marginTop: '50px' }}
                />
              ) : (
                <Line
                  {...{
                    ...(analyticsData['user_messages'] ?? emptyResponse['user_messages']),
                    autoFit: true,
                  }}
                />
              )}
            </Card>
          </Col>
          <Col span={12} xs={24} sm={12} md={8} lg={12} style={{ marginBottom: '16px' }}>
            <Card bordered={true}>
              <Title
                style={{
                  margin: 0,
                  textAlign: 'center',
                  fontSize: 18,
                  justifySelf: 'center',
                }}
              >
                {t('analytics_conversations')}
              </Title>
              {!analyticsData || loading ? (
                <Spin
                  size='large'
                  style={{ display: 'block', textAlign: 'center', marginTop: '50px' }}
                />
              ) : (
                <Line {...(analyticsData['conversations'] ?? emptyResponse['conversations'])} />
              )}
            </Card>
          </Col>
          <Col span={12} xs={24} sm={12} md={8} lg={12} style={{ marginBottom: '16px' }}>
            <Card bordered={true}>
              <Title
                style={{
                  margin: 0,
                  textAlign: 'center',
                  fontSize: 18,
                  justifySelf: 'center',
                }}
              >
                {t('analytics_active_users')}
              </Title>
              {!analyticsData || loading ? (
                <Spin
                  size='large'
                  style={{ display: 'block', textAlign: 'center', marginTop: '50px' }}
                />
              ) : (
                <Column
                  {...(analyticsData['active_users_per_date'] ??
                    emptyResponse['active_users_per_date'])}
                />
              )}
            </Card>
          </Col>
          <Col span={12} xs={24} sm={12} md={8} lg={12} style={{ marginBottom: '16px' }}>
            <Card bordered={true}>
              <Title
                style={{
                  margin: 0,
                  textAlign: 'center',
                  fontSize: 18,
                  justifySelf: 'center',
                }}
              >
                {t('analytics_total_users')}
              </Title>
              {!analyticsData || loading ? (
                <Spin
                  size='large'
                  style={{ display: 'block', textAlign: 'center', marginTop: '50px' }}
                />
              ) : (
                <Column
                  {...(analyticsData['total_users_per_date'] ??
                    emptyResponse['total_users_per_date'])}
                />
              )}
            </Card>
          </Col>
          <Col span={12} xs={24} sm={12} md={8} lg={12} style={{ marginBottom: '16px' }}>
            <Card bordered={true}>
              <Title
                style={{
                  margin: 0,
                  textAlign: 'center',
                  fontSize: 18,
                  justifySelf: 'center',
                }}
              >
                {t('analytics_top_5_most_used_equipments')}
              </Title>
              {!analyticsData || loading ? (
                <Spin
                  size='large'
                  style={{ display: 'block', textAlign: 'center', marginTop: '50px' }}
                />
              ) : (
                <Bar
                  {...(analyticsData['top_5_most_used_equipments'] ??
                    emptyResponse['top_5_most_used_equipments'])}
                />
              )}
            </Card>
          </Col>
          <Col span={12} xs={24} sm={12} md={8} lg={12} style={{ marginBottom: '16px' }}>
            <Card bordered={true}>
              <Title
                style={{
                  margin: 0,
                  textAlign: 'center',
                  fontSize: 18,
                  justifySelf: 'center',
                }}
              >
                {t('analytics_word_cloud')}
              </Title>
              {!analyticsData || loading ? (
                <Spin
                  size='large'
                  style={{ display: 'block', textAlign: 'center', marginTop: '50px' }}
                />
              ) : (
                <WordCloud {...(analyticsData['wordcloud'] ?? emptyResponse['wordcloud'])} />
              )}
            </Card>
          </Col>
          <Col span={24} xs={24} sm={12} md={8} lg={24} style={{ marginBottom: '16px' }}>
            <Card bordered={true}>
              <Title
                style={{
                  margin: 0,
                  textAlign: 'center',
                  fontSize: 18,
                  justifySelf: 'center',
                }}
              >
                {t('analytics_most_common_session_themes')}
              </Title>
              {!analyticsData || loading ? (
                <Spin
                  size='large'
                  style={{ display: 'block', textAlign: 'center', marginTop: '50px' }}
                />
              ) : (
                <Bar
                  {...(analyticsData['most_common_session_themes'] ??
                    emptyResponse['most_common_session_themes'])}
                />
              )}
            </Card>
          </Col>
          <Col span={12} xs={24} sm={12} md={8} lg={12} style={{ marginBottom: '16px' }}>
            <Card bordered={true}>
              <Title
                style={{
                  margin: 0,
                  textAlign: 'center',
                  fontSize: 18,
                  justifySelf: 'center',
                }}
              >
                {t('analytics_messages_and_unanswered')}
              </Title>
              {!analyticsData || loading ? (
                <Spin
                  size='large'
                  style={{ display: 'block', textAlign: 'center', marginTop: '50px' }}
                />
              ) : (
                <DualAxes
                  {...(analyticsData['unanswered_users_per_day_plot'] ??
                    emptyResponse['unanswered_users_per_day_plot'])}
                />
              )}
            </Card>
          </Col>
          <Col span={12} xs={24} sm={12} md={8} lg={12} style={{ marginBottom: '16px' }}>
            <Card bordered={true}>
              <Title
                style={{
                  margin: 0,
                  textAlign: 'center',
                  fontSize: 18,
                  justifySelf: 'center',
                }}
              >
                {t('analytics_messages_breakdown')}
              </Title>
              {!analyticsData || loading ? (
                <Spin
                  size='large'
                  style={{ display: 'block', textAlign: 'center', marginTop: '50px' }}
                />
              ) : (
                <Column
                  {...{
                    ...(analyticsData['unanswered_by_equipment_plot'] ??
                      emptyResponse['unanswered_by_equipment_plot']),
                    style: {
                      fill: (row, index) => row.color,
                    },
                    interaction: {
                      tooltip: {
                        render: (event, { title, items }) => {
                          return (
                            analyticsData['unanswered_by_equipment_plot']['data'] ??
                            emptyResponse['unanswered_by_equipment_plot']['data']
                          )?.map((item) => {
                            if (item.Equipment === title) {
                              return (
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <div
                                    style={{
                                      width: '8px',
                                      height: '8px',
                                      borderRadius: '50%',
                                      backgroundColor: item.color,
                                      marginRight: '5px',
                                    }}
                                  ></div>
                                  <div
                                    key={item.Equipment}
                                  >{`${item.Equipment}: ${item.value}% ${item.type}`}</div>
                                </div>
                              );
                            }
                          });
                        },
                      },
                    },
                  }}
                />
              )}
            </Card>
          </Col>
          <Col span={12} xs={24} sm={12} md={8} lg={12} style={{ marginBottom: '16px' }}>
            <Card bordered={true}>
              <Title
                style={{
                  margin: 0,
                  textAlign: 'center',
                  fontSize: 18,
                  justifySelf: 'center',
                }}
              >
                {t('analytics_answered_vs_unanswered')}
              </Title>
              {!analyticsData || loading ? (
                <Spin
                  size='large'
                  style={{ display: 'block', textAlign: 'center', marginTop: '50px' }}
                />
              ) : (
                <Column
                  {...{
                    ...(analyticsData['unanswered_by_top_10_users_plot'] ??
                      emptyResponse['unanswered_by_top_10_users_plot']),
                    style: {
                      fill: (row, index) => row.color,
                    },
                    interaction: {
                      tooltip: {
                        render: (event, { title, items }) => {
                          return (
                            analyticsData['unanswered_by_top_10_users_plot']['data'] ??
                            emptyResponse['unanswered_by_top_10_users_plot']['data']
                          )?.map((item) => {
                            if (item.User === title) {
                              return (
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <div
                                    style={{
                                      width: '8px',
                                      height: '8px',
                                      borderRadius: '50%',
                                      backgroundColor: item.color,
                                      marginRight: '5px',
                                    }}
                                  ></div>
                                  <div
                                    key={item.User}
                                  >{`${item.User}: ${item.value}% ${item.type}`}</div>
                                </div>
                              );
                            }
                          });
                        },
                      },
                    },
                  }}
                />
              )}
            </Card>
          </Col>
          <Col span={12} xs={24} sm={12} md={8} lg={12} style={{ marginBottom: '16px' }}>
            <Card bordered={true}>
              <Title
                style={{
                  margin: 0,
                  textAlign: 'center',
                  fontSize: 18,
                  width: 320,
                  justifySelf: 'center',
                }}
              >
                {t('analytics_messages_per_day_per_equipment')}
              </Title>
              {!analyticsData || loading ? (
                <Spin
                  size='large'
                  style={{ display: 'block', textAlign: 'center', marginTop: '50px' }}
                />
              ) : (
                <Column
                  {...{
                    ...(analyticsData['unanswered_per_day_and_equipment_plot'] ??
                      emptyResponse['unanswered_per_day_and_equipment_plot']),
                    style: {
                      fill: (row, index) => row.color,
                    },
                    interaction: {
                      tooltip: {
                        render: (event, { title, items }) => {
                          return (
                            analyticsData['unanswered_per_day_and_equipment_plot']['data'] ??
                            emptyResponse['unanswered_per_day_and_equipment_plot']['data']
                          )?.map((item) => {
                            if (item.date === title) {
                              return (
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <div
                                    style={{
                                      width: '8px',
                                      height: '8px',
                                      borderRadius: '50%',
                                      backgroundColor: item.color,
                                      marginRight: '5px',
                                    }}
                                  ></div>
                                  <div key={item.Equipment}>{`${item.type}: ${item.value}`}</div>
                                </div>
                              );
                            }
                          });
                        },
                      },
                    },
                  }}
                />
              )}
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
