import Assets from './pages/plants/Assets';
import { MaintenanceHome } from './pages/Home/Home';
import Interconnections from './pages/plants/interconnections/Interconnections/index.jsx';
import PlantsOutlet from './pages/plants/Outlet';
import PlantsGrid from './pages/plants/Plants';
import SystemsOutlet from './pages/plants/systems/Outlet.jsx';
import SystemsGrid from './pages/plants/systems/Systems/index.jsx';
import EditSystem from './pages/plants/systems/EditSystem'
import System from './pages/plants/systems/ViewSystem'

import WOOutlet from './pages/plants/workOrders/Outlet.jsx';
import WorkOrders from './pages/plants/workOrders/WorkOrders/index.jsx';

import Instruments from './pages/instruments/Instruments';
import InstrumentDetails from './pages/instruments/Details';
import AllAssets from './pages/AllAssets';
import Details from './pages/plants/Details';

import { Navigate } from 'react-router-dom';

import ExecutorsOutlet from './pages/plants/Executors/Outlet.jsx';
import Executors from './pages/plants/Executors/Crud/index.jsx';
import ExecutorsDetails from './pages/plants/Executors/Details/index.jsx';

import InterconnectionsOutlet from './pages/plants/interconnections/Outlet.jsx'
import InterconnectionDetails from './pages/plants/interconnections/Details/index.jsx';
import InstrumentsOutlet from './pages/instruments/Outlet';
import WODetails from './pages/plants/workOrders/Details/index.jsx';
import Reports from './pages/plants/Reports/index.jsx';


export default [
  {
    index: true,
    element: <Navigate to='/maintenance/plants' replace />,
  },
  // { path: '', element: <MaintenanceHome /> },
  {
    path: 'plants',
    element: <PlantsOutlet />,
    children: [
      { path: '', element: <PlantsGrid /> },
      { path: ':plantId?', element: <Assets /> },
      { path: ':plantId?/assets/:assetId?', element: <Details /> },
      {
        path: ':plantId?/interconnections',
        element: <InterconnectionsOutlet />,
        children: [
          { path: '', element: <Interconnections /> },
          {
            path: ':interconnectionId',
            element: <InterconnectionDetails />,
          },
        ],
      },
      { path: ':plantId?/positions/:assetId?', element: <Details /> },
      {
        path: ':plantId?/systems', element: <SystemsOutlet />,
        children: [
          { path: '', element: <SystemsGrid /> },
          { path: ':systemId', element: <EditSystem /> },
          { path: ':systemId/view', element: <System /> }
        ]
      },
      {
        path: ':plantId?/work-orders',
        element: <WOOutlet />,
        children: [
          { path: '', element: <WorkOrders /> },
          {
            path: ':woId',
            element: <WODetails />,
          },
        ],
      },
      {
        path: ':plantId?/reports',
        element: <Reports />,

      },
    ],
  },
  {
    path: 'assets',
    element: <AllAssets />,
  },
  {
    path: 'instruments',
    element: <InstrumentsOutlet />,
    children: [
      { path: '', element: <Instruments /> },
      { path: ':instrumentId', element: <InstrumentDetails /> },
    ],
  },
  {
    path: 'executors',
    element: <ExecutorsOutlet />,
    children: [
      { path: '', element: <Executors /> },
      {
        path: ':executorId',
        element: <ExecutorsDetails />,
      },
    ],
  },
];
