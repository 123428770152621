import { Button } from 'antd';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import '../style.css';
import Input from '../../../../Template/layoutComponents/Input';

const DeleteModal = ({ chosenItem, handleDelete }) => {
  const { t } = useTranslation();
  const [deleteInputValue, setDeleteInputValue] = useState('');

  return (
    <div className='deleteTreeModalContent'>
      <div className='deleteTreeModalContentText'>
        <Trans
          i18nKey='delete_tree_item_are_you_sure'
          values={{ assetName: chosenItem?.name }}
          components={{ strong: <strong />, p: <p /> }}
        />
      </div>
      <div className='deleteTreeModalControls'>
        <Input
          type='text'
          placeholder={chosenItem?.name}
          value={deleteInputValue}
          onChange={(e) => setDeleteInputValue(e.target.value)}
        />
        <Button
          size='large'
          style={{
            borderColor: '#FF4D4F',
            background: '#FF4D4F',
            color: 'white',
            opacity: deleteInputValue === chosenItem?.name ? 1 : 0.5,
          }}
          disabled={deleteInputValue !== chosenItem?.name}
          onClick={() => {
            setDeleteInputValue('');
            handleDelete();
          }}
        >
          {t('i_understand_delete')}
        </Button>
      </div>
    </div>
  );
};

export default DeleteModal;
