import { useMsal } from '@azure/msal-react';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import useTemplateState from '../../../../Template/atoms/variables';
import { useEffect, useState } from 'react';

import { checkPendingTasks } from '../../../helpers/workOrder';
import Table from '../../../../Template/layoutComponents/Table';
import Checkbox from '../../../../Template/layoutComponents/Checkmark';

const TaskTable = ({ language, selectedObjects, pendingTasks, setPendingTasks }) => {
  const { instance } = useMsal();
  const { t } = useTranslation();

  const [notificationApi, contextHolder] = notification.useNotification();

  const { setPendingApiCalls } = useTemplateState();

  const [tableColumns, setTableColumns] = useState(null);

  useEffect(() => {
    triggerApiCalls();
  }, []);

  useEffect(() => {
    const allTests = pendingTasks
      .reduce((acc, entry) => {
        return (acc = acc.concat(entry.tests));
      }, [])
      .map((test) => test.test)
      .filter((value, index, self) => self.findIndex((i) => i._id === value._id) === index);

    const columns = allTests.reduce((acc, entry) => {
      acc[entry[language].key] = {
        label: t(entry[language].key),
      };
      return acc;
    }, {});

    setTableColumns({ name: { label: t('name') }, ...columns });
  }, [pendingTasks]);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['checkPendingTasks']));

      const responses = await Promise.all([
        checkPendingTasks(
          instance,
          selectedObjects.map((obj) => obj._id),
        ),
      ]);

      setPendingTasks(
        responses[0]?.map((task) => ({
          ...task,
          subject_kind: selectedObjects.find((obj) => obj._id === task.subject._id)?.kind,
        })),
      );
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
      console.log(err);
    } finally {
      setPendingApiCalls((current) => current.filter((item) => item !== 'checkPendingTasks'));
    }
  };

  const addCheckboxLogic = (items) => {
    return items.map((item, index) => ({
      ...Object.keys(tableColumns).reduce((acc, testKey) => {
        const columnTestStatus = item?.tests.find((t) => t.test[language].key === testKey);
        acc[testKey] = columnTestStatus ? (
          <Checkbox
            key={`checkbox-${item.subject.name}-${index}`}
            label=''
            disabled={
              columnTestStatus === undefined
                ? true
                : columnTestStatus?.scheduled || !columnTestStatus?.pending
            }
            checked={
              columnTestStatus?.scheduled || !columnTestStatus?.pending
                ? true
                : columnTestStatus.checked
            }
            onChange={(e) => {
              updatePendingTasks(item, testKey, e);
            }}
          />
        ) : (
          <div style={{ textAlign: 'center' }}>-</div>
        );

        return acc;
      }, {}),
      name: item.subject.name,
    }));
  };

  const updatePendingTasks = (item, testKey, e) => {
    setPendingTasks((current) =>
      current.map((i) => {
        if (i.subject._id === item.subject._id) {
          return {
            ...i,
            tests: i.tests.map((t) => {
              if (t.test[language].key === testKey) {
                return { ...t, checked: e.target.value };
              } else return t;
            }),
          };
        } else return i;
      }),
    );
  };

  return (
    <>
      {contextHolder}
      <Table
        handleSelect={null}
        handleRowClick={null}
        apiCall={(instance, query) => {
          return {
            page: query.page,
            limit: query.limit,
            totalItems: pendingTasks.length ?? 0,
            totalPages: Math.ceil(pendingTasks.length / query.limit),
            items: addCheckboxLogic(
              pendingTasks.slice(query.limit * (query.page - 1), query.limit * query.page),
            ),
          };
        }}
        items={addCheckboxLogic(pendingTasks)}
        tableSettings={{
          totalItems: pendingTasks.length ?? 0,
          columnsConfig: { ...tableColumns },
          sortable: [],
          listFilter: [],
          searchFilter: [],
        }}
      />
    </>
  );
};
export default TaskTable;
