import React from 'react';
import { Navigate } from 'react-router-dom';

import SettingsHome from './pages/Home';
import AssetTreeBuild from './pages/asset-tree/AssetTreeBuild';
import AssetTreeManage from './pages/asset-tree/AssetTreeManage';
import AssetCategories from './pages/asset-tree/AssetCategories';
import AssetTreeOutlet from './pages/asset-tree/Outlet';
import Details from './pages/asset-tree/Details';

import OrganizationsOutlet from './pages/organizations/Outlet';
import Organizations from './pages/organizations/Crud';
import OrganizationDetails from './pages/organizations/Details';

import DepartmentsOutlet from './pages/departments/Outlet';
import Departments from './pages/departments/Crud';
import DepartmentsDetails from './pages/departments/Details';

import CostCentersOutlet from './pages/costCenters/Outlet';
import CostCenters from './pages/costCenters/Crud';
import CostCentersDetails from './pages/costCenters/Details';

import ManufacturersOutlet from './pages/manufacturers/Outlet';
import Manufacturers from './pages/manufacturers/Crud';
import ManufacturersDetails from './pages/manufacturers/Details';

import UsersOutlet from './pages/users/Outlet';
import Users from './pages/users/Crud';
import UserDetails from './pages/users/Details';
import UserGroupsOutlet from './pages/userGroups/Outlet';
import UserGroups from './pages/userGroups/Crud';
import UserGroupDetails from './pages/userGroups/Details';
import KnowledgeOutlet from './pages/knowledge/Outlet';
import ManageKnowledge from './pages/knowledge/Manage';
import Analytics from './pages/Analytics/virtual-engineer';
import ManagementOutlet from './pages/Analytics/Outlet';

export default [
  { path: '', element: <Navigate to='asset-tree' replace /> },
  {
    path: 'organizations', element: <OrganizationsOutlet />,
    children: [
      { path: '', element: <Organizations /> },
      { path: ':categoryId', element: <OrganizationDetails /> }
    ]
  },
  {
    path: 'departments', element: <DepartmentsOutlet />,
    children: [
      { path: '', element: <Departments /> },
      { path: ':categoryId', element: <DepartmentsDetails /> }
    ]
  },
  {
    path: 'cost-centers', element: <CostCentersOutlet />, children: [
      { path: '', element: <CostCenters /> },
      { path: ':categoryId', element: <CostCentersDetails /> }
    ]
  },
  {
    path: 'asset-tree',
    element: <AssetTreeOutlet />,
    children: [
      { path: '', element: <AssetTreeBuild /> },
      { path: 'build', element: <AssetTreeBuild /> },
      { path: 'manage', element: <AssetTreeManage /> },
      { path: 'manage/:chosenType', element: <AssetCategories /> },
      { path: 'manage/:chosenType/:categoryId', element: <Details /> },

    ],
  },
  {
    path: 'knowledge',
    element: <KnowledgeOutlet />,
    children: [
      { path: '', element: <Navigate to='manage' replace /> },
      { path: 'manage', element: <ManageKnowledge /> },
    ],
  },
  {
    path: 'inventory',
    element: <ManufacturersOutlet />,
    children: [
      { path: '', element: <Navigate to='manufacturers' replace /> },
      { path: 'manufacturers', element: <Manufacturers /> },
      { path: 'manufacturers/:categoryId', element: <ManufacturersDetails /> },
    ],
  },
  {
    path: 'user-groups', element: <UserGroupsOutlet />, children: [
      { path: '', element: <UserGroups /> },
      { path: ':categoryId', element: <UserGroupDetails /> }
    ]
  },
  {
    path: 'users', element: <UsersOutlet />, children: [
      { path: '', element: <Users /> },
      { path: ':userId', element: <UserDetails /> }
    ]
  },
  {
    path: 'manage-products',
    element: <ManagementOutlet />,
    children: [
      {
        index: true,
        element: <Navigate to='/settings/manage-products/virtualengineer' replace />,
      },
      { path: 'virtualengineer', element: <Analytics /> }
    ],
  },
];
