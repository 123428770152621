import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useEffect, useState } from 'react';
import useMaintenanceState from '../../../../../Maintenance/atoms/variables';

import './style.css';
import useVirtualEngineerState from '../../../../../VirtualEnginner/atoms/variables';
import i18n from '../../../../../_shared/translate/translate-config';
import { useTranslation } from 'react-i18next';
import useSettingsState from '../../../../../Settings/atoms/variables';

const Breadcrumbs = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { chosenPlant, existingAssetTrees, chosenInstrument, chosenSystem, viewDetailsItem } =
    useMaintenanceState();
  const { viewDetailsCategory } = useSettingsState();
  const { currentEquipment } = useVirtualEngineerState();
  const {
    plantId,
    assetId,
    equipmentId,
    instrumentId,
    categoryId,
    systemId,
    interconnectionId,
    userId,
    executorId,
    woId,
  } = useParams();

  const { t } = useTranslation();
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const labels = [
    {
      key: '/maintenance',
      label: t('maintenance'),
      child: [
        {
          key: `/plants`,
          label: i18n.t('plants'),
          child: [
            {
              key: `/assets`,
              label: i18n.t('assets'),
              child: [],
            },
            {
              key: `/positions`,
              label: i18n.t('positions'),
              child: [],
            },
            {
              key: `/interconnections`,
              label: i18n.t('interconnections'),
              child: [],
            },
            {
              key: `/systems`,
              label: i18n.t('systems'),
              child: [],
            },
            {
              key: `/work-orders`,
              label: i18n.t('work_orders'),
              child: [],
            },
            {
              key: `/reports`,
              label: i18n.t('reports'),
            },
          ],
        },
        { key: '/assets', label: i18n.t('all_assets') },
        {
          key: '/instruments',
          label: i18n.t('instruments'),
          child: [],
        },
        {
          key: '/executors',
          label: i18n.t('executors'),
          child: [],
        },
      ],
    },
    {
      key: '/virtual-engineer',
      label: i18n.t('virtual_engineer'),
      child: [
        {
          key: '/equipments',
          label: i18n.t('equipments'),
          child: [],
        },
      ],
    },
    {
      key: '/safety-copilot',
      label: t('safety_copilot'),
    },
    {
      key: '/settings',
      label: t('administration'),
      child: [
        {
          key: '/organizations',
          label: t('organizations'),
          child: [],
        },
        {
          key: '/departments',
          label: t('departments'),
          child: [],
        },
        {
          key: '/cost-centers',
          label: t('cost_centers'),
          child: [],
        },
        {
          key: '/inventory',
          label: i18n.t('inventory'),
          child: [
            {
              key: '/manufacturers',
              label: t('manufacturers'),
              child: [],
            },
          ],
        },
        {
          key: '/knowledge',
          label: i18n.t('manage_knowledge'),
          child: [],
        },
        {
          key: '/asset-tree',
          label: t('asset_tree'),
          child: [
            {
              key: '/build',
              label: t('build'),
            },
            {
              key: '/manage',
              label: t('manage'),
              child: [
                { key: '/positions', label: t('positions'), child: [] },
                { key: '/assets', label: t('assets'), child: [] },
                { key: '/instruments', label: t('instruments'), child: [] },
                { key: '/interconnections', label: t('interconnections'), child: [] },
              ],
            },
          ],
        },
        {
          key: '/user-groups',
          label: t('user_groups'),
          child: [],
        },
        {
          key: '/users',
          label: t('users'),
          child: [],
        },
        {
          key: '/manage-products',
          label: t('manage_products'),
          child: [
            {
              key: '/virtualengineer',
              label: t('virtual_engineer'),
            },
          ],
        },
      ],
    },
  ];

  useEffect(() => {
    setBreadcrumbs(getLabelsFromUrl(location.pathname, labels));
  }, [location, t, chosenPlant, viewDetailsItem, viewDetailsCategory]);

  function getLabelsFromUrl(pathname, structure) {
    const urlSegments = pathname.split('/').filter(Boolean);
    const labels = [];

    function checkSegments(segments, items) {
      if (segments.length === 0) return;

      let [currentSegment, ...remainingSegments] = segments;

      if (currentSegment === plantId && chosenPlant?.name) {
        labels.push({
          key: `/${plantId}`,
          label: chosenPlant.id === 'newPlant' ? t('new_plant') : chosenPlant.name,
        });
        segments.splice(segments.indexOf(plantId), 1);
        [currentSegment, ...remainingSegments] = segments;
      }

      if (currentSegment && currentSegment === systemId) {
        labels.push({ key: `/${systemId}`, label: chosenSystem?.name });
        segments.splice(segments.indexOf(systemId), 1);
        [currentSegment, ...remainingSegments] = segments;
      }

      if (currentSegment === assetId) {
        labels.push({ key: `/${assetId}`, label: findItemName(assetId) });
        segments.splice(segments.indexOf(assetId), 1);
        [currentSegment, ...remainingSegments] = segments;
      }

      if (currentSegment && currentSegment === interconnectionId) {
        labels.push({ key: `/${interconnectionId}`, label: viewDetailsItem?.name });
        segments.splice(segments.indexOf(assetId), 1);
        [currentSegment, ...remainingSegments] = segments;
      }

      if (currentSegment && currentSegment === equipmentId) {
        labels.push({ key: `/${equipmentId}`, label: currentEquipment?.name });
        segments.splice(segments.indexOf(equipmentId), 1);
        [currentSegment, ...remainingSegments] = segments;
      }

      if (currentSegment && currentSegment === instrumentId) {
        labels.push({ key: `/${instrumentId}`, label: viewDetailsItem?.name });
        segments.splice(segments.indexOf(instrumentId), 1);
        [currentSegment, ...remainingSegments] = segments;
      }

      if (currentSegment && currentSegment === categoryId) {
        labels.push({ key: `/${categoryId}`, label: viewDetailsCategory?.label });
        segments.splice(segments.indexOf(categoryId), 1);
        [currentSegment, ...remainingSegments] = segments;
      }

      if (currentSegment && currentSegment === executorId) {
        labels.push({ key: `/${executorId}`, label: viewDetailsItem?.label });
        segments.splice(segments.indexOf(executorId), 1);
        [currentSegment, ...remainingSegments] = segments;
      }

      if (currentSegment && currentSegment === userId) {
        labels.push({ key: `/${userId}`, label: viewDetailsCategory?.label });
        segments.splice(segments.indexOf(userId), 1);
        [currentSegment, ...remainingSegments] = segments;
      }

      if (currentSegment && currentSegment === woId) {
        labels.push({ key: `/${woId}`, label: viewDetailsItem?.token });
        segments.splice(segments.indexOf(woId), 1);
        [currentSegment, ...remainingSegments] = segments;
      }

      for (const item of items) {
        let itemKey = item.key;

        if (itemKey.includes(currentSegment)) {
          labels.push({ key: item.key, label: item.label });

          if (item.child) {
            return checkSegments(remainingSegments, item.child);
          }
        }
      }
    }

    checkSegments(urlSegments, structure);

    return labels.filter((label) => label.label);
  }

  function findItemName(id) {
    function checkIfIsItem(id, item) {
      if (item.id === id) return item;
      else {
        for (let child of item.children) {
          let item = checkIfIsItem(id, child);
          if (item) return item;
        }
      }
    }

    if (existingAssetTrees) {
      let tree = existingAssetTrees?.find((tree) => tree?.id === plantId);
      if (tree) {
        const item = checkIfIsItem(id, tree);
        if (item) return item.name;
      }
    }
  }

  function buildNewPath(index) {
    const tmp = [...breadcrumbs];
    const keys = tmp?.filter((item, itemIndex) => itemIndex <= index)?.map((item) => item?.key);

    return keys.join('');
  }

  return (
    window.screen.width >= 980 && (
      <div className='breadcrumbsContainer'>
        {breadcrumbs.map((bread, index) => (
          <>
            <div
              key={`breadcrumb-${index}`}
              className={`breadcrumb ${bread.key === plantId || bread.key === assetId ? 'disabled' : ''}`}
              onClick={() => {
                navigate(buildNewPath(index));
              }}
            >
              <div
                className={`breadcrumbText ${index === breadcrumbs?.length - 1 ? 'current' : ''} `}
              >
                {bread.label}
              </div>
            </div>
            {index != breadcrumbs?.length - 1 && <div className='breadcrumbDivider'>/</div>}
          </>
        ))}
      </div>
    )
  );
};

export default Breadcrumbs;
