import { useNavigate, useParams } from 'react-router-dom';
import Grid from '../../../../../Template/layoutComponents/Grid';
import { useTranslation } from 'react-i18next';
import useMaintenanceState, { chosenPlant } from '../../../../atoms/variables';
import { getNewSystem } from '../../../../helpers/schemas';
import useTemplateState from '../../../../../Template/atoms/variables';
import { useEffect, useState } from 'react';
import { notification } from 'antd';
import { getSystems } from '../../../../helpers/apiCalls';
import { useMsal } from '@azure/msal-react';

const Systems = () => {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const { t } = useTranslation();
  const [notificationApi, contextHolder] = notification.useNotification();
  const { systems, setSystems, setChosenSystem } = useMaintenanceState();

  const { plantId } = useParams();

  const { setPendingApiCalls } = useTemplateState();

  const [gridSystems, setGridSystems] = useState(systems);

  useEffect(() => {
    triggerApiCalls();
  }, []);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['getSystems']));
      const responses = await Promise.all([getSystems(instance, { parent: plantId })]);

      setGridSystems(responses[0]);
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
    } finally {
      setPendingApiCalls((current) => current.filter((item) => item !== 'getSystems'));
    }
  };

  const handleClickNew = () => {
    setChosenSystem(getNewSystem(t));
    navigate(`/maintenance/plants/${plantId}/systems/newSystem`);
  };
  const handleClickItem = (item) => {
    setChosenSystem(item);
    navigate(`/maintenance/plants/${plantId}/systems/${item.id}/view`);
  };

  return (
    <>
      {contextHolder}
      <Grid items={gridSystems} onClickItem={handleClickItem} onClickNew={handleClickNew} />
    </>
  );
};
export default Systems;
