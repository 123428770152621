import { useTranslation } from 'react-i18next';
import success from '../../../../../images/success.svg';
import useGlobalState from '../../../../../../Template/atoms/variables';

const SuccessModal = () => {
  const { t } = useTranslation();
  const { setModalOpen, setModalStyle } = useGlobalState();

  return (
    <div
      className='addKnowledgeModalContent'
      onClick={() => {
        setModalOpen(false);
        setModalStyle(null);
      }}
    >
      <div className='addKnowledgeLoading'>
        <img style={{ height: '10rem' }} src={success} />
        <div className='addKnowledgeLoadingText'>{t('success_modal_title')}</div>
      </div>
    </div>
  );
};

export default SuccessModal;
