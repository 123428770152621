import { ExportOutlined } from '@ant-design/icons';

import './style.css';
import useMaintenanceState from '../../../../../../../atoms/variables';
import { Button, notification } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import SystemTable from './components/SystemTable';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const SystemDependency = ({ asset, phaseTitle, setAllTasks, testId, setStepProgress }) => {
  const navigate = useNavigate();
  const { plantId } = useParams();
  const { t } = useTranslation();

  const [allItems, setAllItems] = useState([]);

  const addSystemItems = (newItems) => {
    setAllItems((current) => {
      const itemsToAdd = newItems.filter(
        (item) => !current.find((existing) => existing._id === item._id),
      );

      return current.concat(itemsToAdd);
    });
  };

  useEffect(() => {
    setStepProgress((current) => ({
      ...current,
      [phaseTitle]: Math.round(
        (allItems.filter((item) => item.status?.toLowerCase() === 'approved').length /
          allItems.length) *
          100,
      ),
    }));
    if (
      allItems.length > 0 &&
      allItems.every((item) => item.status?.toLowerCase() === 'approved')
    ) {
      setAllTasks((prev) => [...prev, { test: { _id: testId }, report: { status: 'approved' } }]);
    } else {
      setAllTasks((prev) => [
        ...prev?.filter((t) => t.test._id != testId),
        { test: { _id: testId }, report: { status: 'pending' } },
      ]);
    }
  }, [allItems]);

  return (
    <div className='systemDependency'>
      {asset.system?.length > 0 ? (
        asset?.system.map((system) => {
          return <SystemTable system={system} addSystemItems={addSystemItems} />;
        })
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <div className='systemDependencyHeader'>
            <div className='systemNameContainer'>
              <div className='systemName'>{t('no_system_header')}</div>
              <div className='systemNavigate'></div>
            </div>
          </div>
          <div className='systemContent'>
            {t('no_system_body')}
            <div style={{}}>
              <Button
                size='large'
                icon={<ExportOutlined />}
                style={{
                  width: 'fit-content',
                  borderColor: '#1777FF',
                  background: '#1777FF',
                  color: 'white',
                }}
                onClick={() => {
                  navigate(`/maintenance/plants/${plantId}/systems`);
                }}
              >
                {t('systems')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SystemDependency;
