import { Button, notification, Tooltip } from 'antd';
import Table from '../../../../../Template/layoutComponents/Table';

import { PlusOutlined, UnlockOutlined } from '@ant-design/icons';

import { getInterconnectionAndUniques, unlockInterconnections } from '../../../../helpers/apiCalls';
import { useMsal } from '@azure/msal-react';
import useTemplateState from '../../../../../Template/atoms/variables';
import useMaintenanceState from '../../../../atoms/variables';
import { useEffect, useState } from 'react';
import { getInterconnectionCategories } from '../../../../../Settings/helpers/apiCalls';
import TableTip from '../../../../../Template/layoutComponents/Table/components/tip';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NewInterconnectionModal from '../components/newInterconnectionModal';
import NewWoModal from '../../../../components/NewWoModal';

import './style.css';

const Interconnections = () => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const { instance } = useMsal();

  const { plantId } = useParams();

  const {
    setPendingApiCalls,
    setModalOpen,
    setModalConfirm,
    setModalContent,
    setModalTitle,
    setCleanOnCancel,
    setModalStyle,
    user,
  } = useTemplateState();
  const { interconnections, setInterconnections, orgs, setViewDetailsItem } = useMaintenanceState();
  const [notificationApi, contextHolder] = notification.useNotification();

  const [showWoButton, setShowWoButton] = useState(false);
  const [showUnlockButton, setShowUnlockButton] = useState(false);
  const [allCategories, setAllCategories] = useState([]);

  const [selectedInterconnections, setSelectedInterconnections] = useState([]);

  const [forceReload, setForceReload] = useState(false);

  useEffect(() => {
    triggerApiCalls();
  }, []);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) =>
        current.concat(['getInterconnectionAndUniques', 'getInterconnectionCategories']),
      );

      const responses = await Promise.all([
        getInterconnectionAndUniques(instance, { location: plantId, limit: 10 }),
        getInterconnectionCategories(instance),
      ]);
      const interconnectionsResponse = responses[0];
      interconnectionsResponse.items.map((item) => {
        item.from = item.properties.find((p) => p.key === 'origin')?.value;
        item.to = item.properties.find((p) => p.key === 'destiny')?.value;
      });
      setInterconnections(interconnectionsResponse);
      setAllCategories(responses[1]);
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
      console.log(err);
    } finally {
      setPendingApiCalls((current) =>
        current.filter(
          (item) =>
            item !== 'getInterconnectionAndUniques' && item !== 'getInterconnectionCategories',
        ),
      );
    }
  };

  const getUnlockToolTip = () => {
    if (
      ['admin', 'quality', 'management'].every(
        (access) => !user?.access?.some((a) => a.toLowerCase() === access),
      )
    ) {
      return t('you_dont_have_access');
    }

    if (selectedInterconnections.length === 0) {
      return t('select_items');
    }

    if (selectedInterconnections.some((i) => i?.status?.toLowerCase() !== 'fail')) {
      return t('some_items_not_locked');
    }
  };

  const getWoToolTip = () => {
    if (selectedInterconnections.length === 0) {
      return t('select_items');
    }

    if (selectedInterconnections.some((i) => i?.status?.toLowerCase() !== 'pending')) {
      return t('some_items_not_pending');
    }
  };

  const unlockInterconnectionsCall = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['unlockInterconnections']));
      await unlockInterconnections(
        instance,
        selectedInterconnections.map((int) => int._id ?? int.id),
      );
      setForceReload(true);
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
    } finally {
      setPendingApiCalls((current) => current.filter((item) => item !== 'unlockInterconnections'));
    }
  };

  return (
    <div className='interconnectionsContent'>
      {contextHolder}
      <div className='interconnectionsContentHeader'>
        <div className='pageTitle'>{t('interconnections_title')}</div>
        <div className='pageButtons'>
          <Tooltip title={getUnlockToolTip()}>
            <Button
              icon={<UnlockOutlined />}
              size='large'
              style={{
                borderColor: '#242424',
                background: '#242424',
                color: 'white',
                opacity: showUnlockButton ? 1 : 0.5,
              }}
              disabled={!showUnlockButton}
              onClick={unlockInterconnectionsCall}
            >
              {t('unlock')}
            </Button>
          </Tooltip>
          <Tooltip title={getWoToolTip()}>
            <Button
              size='large'
              style={{
                borderColor: 'black',
                background: 'white',
                color: 'black',
                opacity: showWoButton ? 1 : 0.5,
              }}
              disabled={!showWoButton}
              onClick={async () => {
                setModalTitle(t('new_wo'));
                setModalContent(
                  <NewWoModal
                    setForceReload={setForceReload}
                    preSelectedSubjectKind={[{ id: 'interconnection', label: '' }]}
                    selectedSubjects={selectedInterconnections}
                  />,
                );
                setModalStyle({ padding: '2rem', gap: '1rem', height: 'unset' });
                setCleanOnCancel(true);
                setModalConfirm(null);
                setModalOpen(true);
              }}
            >
              {t('generate_workorder')}
            </Button>
          </Tooltip>
          <Button
            className='newItemButton'
            size='large'
            icon={<PlusOutlined />}
            style={{
              borderColor: '#1777FF',
              background: '#1777FF',
              color: 'white',
            }}
            onClick={async () => {
              setModalTitle(t('new_interconnection'));
              setModalContent(<NewInterconnectionModal setForceReload={setForceReload} />);
              setModalStyle({ padding: '2rem', gap: '1rem', height: 'unset' });

              setModalConfirm(null);
              setModalOpen(true);
            }}
          >
            {t('new_interconnection')}
          </Button>
        </div>
      </div>
      <Table
        handleSelect={(selected) => {
          setSelectedInterconnections(selected);
          if (selected.length > 0) {
            if (
              !selected.some((item) =>
                ['approved', 'fail', 'scheduled', 'success'].includes(item.status?.toLowerCase()),
              )
            )
              setShowWoButton(true);
            else setShowWoButton(false);

            if (selected.every((item) => item.status?.toLowerCase() === 'fail'))
              setShowUnlockButton(true);
            else setShowUnlockButton(false);
          } else {
            setShowWoButton(false);
            setShowUnlockButton(false);
          }
        }}
        handleRowClick={(row) => {
          setViewDetailsItem({
            id: row.id,
            name: row.name,
            category: row.category,
            properties: row.properties.map((prop) => ({
              ...prop,
              disabled: prop.allowEdit ? false : prop.main,
            })),
          });
          navigate(`/maintenance/plants/${plantId}/interconnections/${row.id}`);
        }}
        apiCall={async (instance, queryParams) => {
          const response = await getInterconnectionAndUniques(instance, {
            location: plantId,
            ...queryParams,
          });
          response.items.map((item) => {
            item.from = item.properties.find((p) => p.key === 'origin')?.value;
            item.to = item.properties.find((p) => p.key === 'destiny')?.value;
          });
          return response;
        }}
        items={interconnections?.items}
        tableSettings={{
          totalItems: interconnections.totalItems ?? 0,
          columnsConfig: {
            category: {
              label: t('category'),
              replaceValue: (value) =>
                allCategories.find((cat) => cat._id === value || cat.id === value),
            },
            name: {
              label: t('name'),
            },
            from: {
              label: t('from'),
              filter: {
                getFilter: (value) => {
                  if (value)
                    return {
                      'properties.origin[elemMatch][key]': 'origin',
                      'properties.origin[elemMatch][value][regex]': value,
                      'properties.origin[elemMatch][value][options]': 'i',
                    };
                  return {
                    'properties.origin[elemMatch][key]': null,
                    'properties.origin[elemMatch][value][regex]': null,
                    'properties.origin[elemMatch][value][options]': null,
                  };
                },
              },
            },
            to: {
              label: t('to'),
              filter: {
                getFilter: (value) => {
                  if (value)
                    return {
                      'properties.destiny[elemMatch][key]': 'destiny',
                      'properties.destiny[elemMatch][value][regex]': value,
                      'properties.destiny[elemMatch][value][options]': 'i',
                    };
                  return {
                    'properties.destiny[elemMatch][key]': null,
                    'properties.destiny[elemMatch][value][regex]': null,
                    'properties.destiny[elemMatch][value][options]': null,
                  };
                },
              },
            },
            status: {
              label: t('status'),
              customElement: (item) => <TableTip children={item.status ?? item} />,
            },
            pendingTasks: {
              label: t('pending_tasks'),
              customElement: (item) => (
                <div className='pendingTasksColumn'>
                  {item.pendingTasks.map((task) => (
                    <TableTip children={task[i18n.language ?? 'en'].title} />
                  ))}
                </div>
              ),
              getSelectOptions: (uniqueValues) => {
                // Agrupar por label
                const groupedByLabel = (uniqueValues.pendingTasks ?? []).reduce((acc, task) => {
                  const label = task.test[i18n.language ?? 'en'].title;
                  if (!acc[label]) {
                    acc[label] = { ids: [], label };
                  }
                  acc[label].ids.push(task._id);
                  return acc;
                }, {});

                // Converter para o formato de opções
                return Object.values(groupedByLabel).map(({ label, ids }) => ({
                  id: ids.length === 1 ? ids[0] : { in: ids },
                  label,
                }));
              },
            },
          },
          sortable: ['category', 'name', 'status'],
          listFilter: ['category', 'status', 'pendingTasks'],
          searchFilter: ['name', 'from', 'to'],
          uniqueValues: interconnections.uniqueValues,
        }}
        forceReload={forceReload}
        setForceReload={setForceReload}
      />
    </div>
  );
};

export default Interconnections;
