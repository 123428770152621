import React, { useCallback, useMemo } from 'react';
import SingleSelect from '../../SingleSelect';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

const ListFilter = ({
  column,
  filter,
  setFilter,
  setShowFilter,
  setFilterPosition,
  triggerApiCall,
  tableSettings,
}) => {
  const { t } = useTranslation();

  // Constantes para estilos dos botões
  const BUTTON_STYLES = {
    clean: {
      borderColor: 'black',
      background: 'white',
      color: 'black',
    },
    filter: {
      borderColor: '#1777FF',
      background: '#1777FF',
      color: 'white',
    },
  };

  // Função auxiliar para obter o label do item
  const getItemLabel = (item, column, tableSettings) => {
    const config = tableSettings?.columnsConfig?.[column.id];
    if (config?.replaceValue) {
      return config.replaceValue(item)?.label;
    }
    if (config?.customElement) {
      return config.customElement(item);
    }
    return item;
  };

  // Memoizar as opções do select para evitar recálculos desnecessários
  const selectOptions = useMemo(() => {
    if (tableSettings?.columnsConfig[column.id].getSelectOptions)
      return tableSettings?.columnsConfig[column.id].getSelectOptions(tableSettings?.uniqueValues);
    const uniqueValues = tableSettings?.uniqueValues?.[column.id] ?? [];
    return uniqueValues.map((item) => ({
      id: `${item}`,
      label: getItemLabel(item, column, tableSettings),
    }));
  }, [tableSettings?.uniqueValues, column.id]);

  const handleSubmit = useCallback(() => {
    setShowFilter(null);
    setFilterPosition(null);
    triggerApiCall();
  }, [setShowFilter, setFilterPosition, triggerApiCall]);

  const handleCleanFilter = useCallback(() => {
    const updateFilter = (current) => {
      const newFilter = {
        ...current,
        [column.id]: undefined,
      };
      triggerApiCall(newFilter);
      return newFilter;
    };

    setFilter(updateFilter);
  }, [column.id, setFilter, triggerApiCall]);

  const handleSelectChange = useCallback(
    (e) => {
      setFilter((current) => ({
        ...current,
        [column.id]: e.target.value,
      }));
    },
    [column.id, setFilter],
  );

  const isFilterDisabled = !filter?.[column.id];

  return (
    <div className='opwellTableCellFilter'>
      <div className='filterTitle'>
        {t('choose_values')} <b>{column.label}</b>
      </div>

      <SingleSelect
        key={`listFilter-${column.id}-${filter?.[column.id]}`}
        value={filter?.[column.id] ? { id: filter[column.id] } : {}}
        options={selectOptions}
        onChange={handleSelectChange}
      />

      <Button
        className={`filterButton ${isFilterDisabled ? 'disabled' : ''}`}
        size='large'
        disabled={isFilterDisabled}
        style={BUTTON_STYLES.clean}
        onClick={handleCleanFilter}
      >
        {t('clean')}
      </Button>

      <Button
        className={`filterButton ${isFilterDisabled ? 'disabled' : ''}`}
        size='large'
        disabled={isFilterDisabled}
        style={BUTTON_STYLES.filter}
        onClick={handleSubmit}
      >
        {t('filter')}
      </Button>
    </div>
  );
};

export default ListFilter;
