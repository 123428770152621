import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import Tabs from '../../../../Template/layoutComponents/Tabs';

import useSettingsState from '../../../atoms/variables';

import './style.css';

import GeneralTab from './components/GeneralTab';
import CommissioningTab from './components/CommissioningTab';
import HistoryTab from '../../../../Template/layoutComponents/HistoryTab';
import {
  getAllCategories,
  getInstrumentCategories,
  getInterconnectionCategories,
} from '../../../helpers/apiCalls';
import UploadDocumentPage from '../../../../Template/layoutComponents/UploadDocumentPage';
import { deleteFile, getFiles, saveFile } from '../../../../Maintenance/helpers/files';
import { useMsal } from '@azure/msal-react';
import useTemplateState from '../../../../Template/atoms/variables';
import { notification } from 'antd';

const Details = () => {
  const { t } = useTranslation();
  const { categoryId, chosenType } = useParams();

  const { instance } = useMsal();
  const [notificationApi, contextHolder] = notification.useNotification();

  const { companyInfo } = useTemplateState();

  const { viewDetailsCategory, setViewDetailsCategory } = useSettingsState();
  const { setPendingApiCalls } = useTemplateState();

  useEffect(() => {
    if (!viewDetailsCategory) {
      triggerApiCalls();
    }
  }, []);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['getAllCategories']));

      let response;
      if (chosenType === 'instruments') {
        response = await getInstrumentCategories(instance);
      } else if (chosenType === 'interconnections') {
        response = await getInterconnectionCategories(instance);
      } else {
        response = await getAllCategories(instance);
      }

      setViewDetailsCategory(response?.find((c) => c.id === categoryId || c._id === categoryId));
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
    } finally {
      setPendingApiCalls((current) => current.filter((item) => item !== 'getAllCategories'));
    }
  };

  return (
    <div id='assetDetails'>
      {contextHolder}
      <Tabs
        tabs={[
          {
            label: t('general_tab'),
            content: <GeneralTab />,
            disabled: false,
          },
          (chosenType == 'assets' || chosenType === 'interconnections') &&
            companyInfo?.commissioning && {
              label: t('commissioning_criteria_tab'),
              content: (
                <CommissioningTab
                  initialPhase={chosenType === 'interconnections' ? 2 : undefined}
                  phasesLenght={chosenType === 'interconnections' ? 1 : undefined}
                />
              ),
              disabled: !viewDetailsCategory?.commissionable,
            },
          {
            label: t('documents_tab'),
            content: (
              <UploadDocumentPage
                id={categoryId}
                type={`${chosenType.slice(0, chosenType.length - 1)}Category`}
                getCall={getFiles}
                uploadCall={saveFile}
                deleteCall={deleteFile}
              />
            ),

            disabled: false,
          },
          {
            label: t('history_tab'),
            content: <HistoryTab id={categoryId} />,
            disabled: false,
          },
        ].filter((tab) => tab)}
      />
    </div>
  );
};

export default Details;
