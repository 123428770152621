import React, { useState } from 'react';

import SingleSelect from '../SingleSelect';

import sortDefault from '../../images/Sort.svg';
import sortAsc from '../../images/SortAsc.svg';
import sortDsc from '../../images/SortDsc.svg';

import paginationLeft from '../../images/paginationLeft.svg';
import paginationRight from '../../images/paginationRight.svg';

import { CloseCircleOutlined } from '@ant-design/icons';

import { useEffect } from 'react';
import Checkbox from '../Checkmark';
import { Button, notification, Skeleton } from 'antd';
import { useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';

import Filter from './components/filter';
import './style.css';

const Table = ({
  style = null,
  items = null,
  tableSettings,
  handleSelect = () => {},
  handleRowClick = () => {},
  apiCall,
  loadingSkeleton,
  forceReload,
  setForceReload,
  startingSort,
  startingSelected = [],
  disableFilters = null,
  ignoreExternalItemsChange = false,
}) => {
  const { t } = useTranslation();

  const { instance } = useMsal();
  const [notificationApi, contextHolder] = notification.useNotification();

  const [data, setData] = useState([]);
  const [columnKeys, setColumnKeys] = useState([]);
  const [columns, setColumns] = useState([]);

  const [sort, setSort] = useState(startingSort ?? { column: null, asc: true });

  const [filter, setFilter] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [pageData, setPageData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  const [selectedItems, setSelectedItems] = useState(startingSelected ?? []);

  const [tableLoading, setTableLoading] = useState(true);
  const [additionalData, setAdditionalData] = useState(null);

  const [reload, setReload] = useState(false);

  useEffect(() => {
    setReload(forceReload);
  }, [forceReload]);

  useEffect(() => {
    if (reload && !tableLoading) {
      triggerApiCall();
      setForceReload(false);
      setSelectedItems([]);
    }
  }, [reload]);

  useEffect(() => {
    if (!ignoreExternalItemsChange || pageData?.length === 0) {
      const tableData = items?.map((item) => {
        return { ...item, id: item._id ?? item.id };
      });
      setData(tableData);

      if (tableSettings?.columnsConfig) {
        setColumnKeys(Object.keys(tableSettings?.columnsConfig));
      } else {
        const keys = tableData?.reduce((acc, entry) => {
          const keys = Object.keys(entry);
          const newKeys = keys.filter((key) => !acc.includes(key));
          acc = acc.concat(newKeys);
          return acc;
        }, []);
        setColumnKeys(keys);
      }
      setTotalPages(Math.ceil(totalItems ?? tableSettings?.totalItems / pageSize));
      if (currentPage > 1) {
        setPageData(
          (data || items)?.slice(pageSize * (currentPage - 1), pageSize * currentPage) ?? [],
        );
      } else {
        setPageData((additionalData?.items ?? tableData)?.slice(0, pageSize));
      }
      setTotalItems(totalItems ?? tableSettings?.totalItems);
    }
  }, [items, t]);

  const getFilterType = (columnKey) => {
    if (disableFilters?.includes(columnKey)) {
      return null;
    }
    if (tableSettings?.listFilter?.includes(columnKey)) {
      return 'list';
    }
    if (tableSettings?.searchFilter?.includes(columnKey)) {
      return 'search';
    }
    return null;
  };

  useEffect(() => {
    setColumns(
      columnKeys?.map((key) => {
        return {
          id: key,
          label: tableSettings?.columnsConfig?.[key]?.label ?? key,
          filter: {
            type: getFilterType(key),
            performsApiCall: tableSettings?.filterPerformsApiCall?.includes(key),
            getFilter: tableSettings?.columnsConfig?.[key]?.filter?.getFilter,
          },
          sortable: tableSettings?.sortable?.includes(key),
        };
      }),
    );
  }, [columnKeys, disableFilters]);

  useEffect(() => {
    setTotalPages(Math.ceil(totalItems / pageSize));

    if (Math.ceil(totalItems / pageSize) < currentPage) {
      setPageData((additionalData?.items ?? data)?.slice(0, pageSize));
      setCurrentPage(1);
    } else {
      setPageData(
        (additionalData?.items ?? data)?.slice(
          pageSize * (currentPage - 1),
          pageSize * currentPage,
        ) ?? [],
      );
    }
    setTableLoading(false);
  }, [pageSize]);

  useEffect(() => {
    if (handleSelect) {
      handleSelect(selectedItems);
    }
  }, [selectedItems]);

  useEffect(() => {
    if (!tableLoading) triggerApiCall();
  }, [pageSize, currentPage, sort]);

  useEffect(() => {
    setTotalPages(Math.ceil(additionalData?.totalItems / pageSize));
    setTotalItems(additionalData?.totalItems);

    if (Math.ceil(additionalData?.totalItems / pageSize) < currentPage) {
      setCurrentPage(1);
    }
    setPageData(
      additionalData?.items?.map((item) => {
        return { ...item, id: item._id ?? item.id };
      }),
    );
  }, [additionalData]);

  const triggerApiCall = async (tmpFilter = null) => {
    try {
      const finalFilter = Object.entries(tmpFilter || filter).reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});

      setTableLoading(true);
      const response = await apiCall(instance, {
        page: Math.ceil(additionalData?.totalItems / pageSize) < currentPage ? 1 : currentPage,
        limit: pageSize,
        sort: sort?.column ? `${sort?.asc ? '' : '-'}${sort?.column}` : '',
        ...finalFilter,
      });
      setAdditionalData(response);
      return response;
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
    } finally {
      setTableLoading(false);
    }
  };

  useEffect(() => {
    if (!ignoreExternalItemsChange) {
      setSelectedItems((current) =>
        [...(current || []), ...startingSelected]?.filter(
          (item) =>
            startingSelected?.some((tableItem) => tableItem?.id === item?.id) ||
            items?.some((tableItem) => tableItem?.id === item?.id),
        ),
      );
    }
  }, [items]);

  return (
    <div className='opwellTableContainer' style={style}>
      {contextHolder}
      <div className='opwellTableButtons'>
        {Object.values(filter)?.filter((item) => item)?.length > 0 && (
          <Button
            className='cleanFilterButton'
            size='large'
            disabled={tableLoading}
            icon={<CloseCircleOutlined />}
            style={{
              borderColor: 'black',
              background: 'white',
              color: 'black',
              opacity: tableLoading ? 0.5 : 1,
            }}
            onClick={() => {
              setFilter({});
              triggerApiCall({});
            }}
          >
            {t('clean_filter')}
          </Button>
        )}
      </div>
      <div className='stickyWorkaround'>
        <div className='stickySpacer'></div>
        <table className='opwellTable'>
          <tr key={`row-title`} className='opwellTableRow title'>
            {handleSelect && (
              <td key={`cell-column-select-row-title`} className='opwellTableCell'>
                <Checkbox
                  checked={pageData?.some((pageItem) => {
                    return selectedItems.find((selectedItem) => selectedItem?.id === pageItem?.id);
                  })}
                  style={{ justifyContent: 'center' }}
                  label=''
                  onChange={() => {
                    if (!tableLoading)
                      if (
                        pageData?.every((pageItem) => {
                          return selectedItems.find(
                            (selectedItem) => selectedItem?.id === pageItem?.id,
                          );
                        })
                      ) {
                        setSelectedItems((current) =>
                          current.filter(
                            (selectedItem) =>
                              !pageData?.some((pageItem) => selectedItem?.id === pageItem?.id),
                          ),
                        );
                      } else
                        setSelectedItems((current) =>
                          current.concat(
                            pageData?.filter(
                              (pageItem) =>
                                !selectedItems.some(
                                  (selectedItem) => selectedItem?.id === pageItem?.id,
                                ),
                            ),
                          ),
                        );
                  }}
                />
              </td>
            )}
            {columns?.map((column, index) => {
              if (tableSettings?.columnsConfig?.[column.id])
                return (
                  <td key={`cell-column${index}-row-title`} className='opwellTableCell'>
                    <div className='opwellTableCellTitleContent'>
                      {column.label}
                      <div
                        key={`opwellTableCellControlls-column${index}-row-title`}
                        className='opwellTableCellControlls'
                      >
                        {column?.sortable && (
                          <div
                            key={`opwellTableCellControll-column${index}-sort`}
                            className='opwellTableCellControll'
                            onClick={() => {
                              if (!tableLoading)
                                setSort((current) => {
                                  if (current.column === column.id) {
                                    if (!current.asc) return { column: null, asc: true };
                                    else return { ...current, asc: !current.asc };
                                  } else {
                                    return { column: column.id, asc: true };
                                  }
                                });
                            }}
                          >
                            <img
                              src={
                                sort?.column === column.id
                                  ? sort.asc
                                    ? sortAsc
                                    : sortDsc
                                  : sortDefault
                              }
                            />
                          </div>
                        )}
                        {column?.filter?.type && (
                          <Filter
                            column={column}
                            filter={filter}
                            setFilter={setFilter}
                            triggerApiCall={triggerApiCall}
                            tableSettings={tableSettings}
                            tableLoading={tableLoading}
                            index={index}
                          />
                        )}
                      </div>
                    </div>
                  </td>
                );
            })}
          </tr>

          {tableLoading || loadingSkeleton
            ? [...Array(3).keys()].map((index) => (
                <tr key={`skeleton-row-${index}`} className='opwellTableRow loading'>
                  {handleSelect && (
                    <td key={`skeleton-select-${index}`} className='opwellTableCell'></td>
                  )}
                  {columns?.map((column, colIndex) => {
                    if (tableSettings?.columnsConfig?.[column.id])
                      return (
                        <td key={`skeleton-cell-${index}-${colIndex}`} className='opwellTableCell'>
                          <Skeleton active title={false} paragraph={{ rows: 1, width: '80%' }} />
                        </td>
                      );
                  })}
                </tr>
              ))
            : pageData?.map((row, rowIndex) => {
                return (
                  <tr
                    key={`row-${rowIndex}-${row?.id}`}
                    className='opwellTableRow'
                    onClick={(e) => {
                      if (handleRowClick) handleRowClick(row);
                    }}
                  >
                    {handleSelect && (
                      <td
                        key={`cell-column-select-row-${rowIndex}`}
                        className='opwellTableCell'
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <Checkbox
                          checked={selectedItems.some(
                            (selectedItem) => selectedItem?.id === row?.id,
                          )}
                          style={{ justifyContent: 'center' }}
                          label=''
                          onChange={() => {
                            if (
                              selectedItems.some((selectedItem) => selectedItem?.id === row?.id)
                            ) {
                              setSelectedItems((current) =>
                                current.filter((selectedItem) => selectedItem?.id !== row?.id),
                              );
                            } else {
                              setSelectedItems((current) => current.concat([row]));
                            }
                          }}
                        />
                      </td>
                    )}
                    {columns?.map((column, index) => {
                      if (tableSettings?.columnsConfig?.[column.id])
                        return (
                          <td
                            key={`cell-column${index}-row-${rowIndex}`}
                            className='opwellTableCell dataRow'
                            style={handleRowClick ? {} : { cursor: 'default' }}
                          >
                            {row[column.id] || row[column.id] == false
                              ? tableSettings?.columnsConfig?.[column.id]?.customElement
                                ? tableSettings?.columnsConfig?.[column.id]?.customElement(row)
                                : tableSettings?.columnsConfig?.[column.id]?.replaceValue
                                  ? tableSettings.columnsConfig[column.id].replaceValue(
                                      row[column.id],
                                    )?.label
                                  : row[column.id]
                              : '-'}
                          </td>
                        );
                    })}
                  </tr>
                );
              })}
        </table>
      </div>
      <div className='opwellTablePagination'>
        <div className='opwellTablePaginationControlls'>
          <div className='pageSizeSelector'>
            <div className='pageSizeSelectorText'>{t('items_per_page')}</div>
            <SingleSelect
              shouldOpenUp
              style={{ width: '5rem' }}
              value={{ id: 10 }}
              options={[
                { id: 5, label: '5' },
                { id: 10, label: '10' },
                { id: 20, label: '20' },
                { id: 50, label: '50' },
                { id: 100, label: '100' },
              ]}
              onChange={(e) => {
                setPageSize(e.target.value);
              }}
            />
          </div>
          <div>
            {tableLoading ? ' ' : (currentPage - 1) * pageSize} -{' '}
            {tableLoading ? ' ' : (currentPage - 1) * pageSize + pageData?.length} /{' '}
            {tableLoading ? '-' : totalItems}
          </div>
          <div className='pageSelector'>
            <div
              className='pageSelectorArrow'
              onClick={() => {
                setCurrentPage((current) => (current > 1 ? current - 1 : current));
              }}
            >
              <img src={paginationLeft} />
            </div>
            <div
              className={`pageSelectorButton ${currentPage === 1 ? 'selected' : ''}`}
              onClick={() => {
                setCurrentPage(1);
              }}
            >
              1
            </div>
            {totalPages > 5 && currentPage >= 4 && (
              <div className={`pageSelectorEllipsis`}>...</div>
            )}
            {[...Array(Number.isFinite(totalPages) && totalPages > 0 ? totalPages : 1).keys()]
              ?.filter((page) => {
                return currentPage < 4
                  ? page + 1 <= 4 && page != 0
                  : currentPage > totalPages - 3
                    ? page + 1 >= totalPages - 3 && page + 1 !== totalPages
                    : page + 1 >= currentPage - 1 &&
                      page + 1 <= currentPage + 1 &&
                      page + 1 !== totalPages;
              })
              ?.map((page) => {
                return (
                  <div
                    className={`pageSelectorButton ${currentPage === page + 1 ? 'selected' : ''}`}
                    onClick={() => {
                      if (!tableLoading) setCurrentPage(page + 1);
                    }}
                  >
                    {page + 1}
                  </div>
                );
              })}
            {totalPages > 5 && currentPage < totalPages - 2 && (
              <div className={`pageSelectorEllipsis`}>...</div>
            )}
            {totalPages >= 5 && (
              <div
                className={`pageSelectorButton ${currentPage === totalPages ? 'selected' : ''}`}
                onClick={() => {
                  setCurrentPage(totalPages);
                }}
              >
                {totalPages}
              </div>
            )}
            <div
              className='pageSelectorArrow'
              onClick={() => {
                setCurrentPage((current) => (current < totalPages ? current + 1 : current));
              }}
            >
              <img src={paginationRight} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
