import { useTranslation } from 'react-i18next';
import useGlobalState from '../../../../../../Template/atoms/variables';
import { Button, notification } from 'antd';
import { generateKnowledge } from '../../../../../helpers/conversation';
import { useMsal } from '@azure/msal-react';
import { useState } from 'react';
import useVirtualEngineerState from '../../../../../atoms/variables';
import EditModal from './EditModal';
import { sleep } from '../../../../../../helpers/misc';
import LoadingModal from './LoadingModal';

const FirstInputModalBody = ({ showModal }) => {
  const [notificationApi, contextHolder] = notification.useNotification();
  const { t } = useTranslation();
  const { instance } = useMsal();
  const { currentEquipment } = useVirtualEngineerState();

  const {
    setModalOpen,
    setModalContent,
    setModalStyle,
    setCleanOnCancel,
    setModalTitle,
    setModalConfirm,
  } = useGlobalState();

  const [input, setInput] = useState('');

  const showLoadingModal = () => {
    setModalTitle('');
    setModalContent(<LoadingModal />);
    setModalStyle({ padding: '2rem', gap: '1rem', minHeight: '70%', minWidth: '55%' });
    setModalConfirm(null);
    setModalOpen(true);
  };

  const handleGenerateClick = async () => {
    try {
      showLoadingModal();

      await sleep(2000);
      const response = await generateKnowledge(instance, input, currentEquipment?._id);

      setModalTitle('');
      setModalContent(
        <EditModal value={response.content} prompt={input} generatorId={response.generatorId} />,
      );
      setModalStyle({ padding: '0', gap: '1rem', minHeight: '90%', minWidth: '90%' });
      setModalConfirm(null);
      setModalOpen(true);
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
      showModal();
    }
  };

  const handleTextAreaChange = (e) => {
    setInput(e.target.value);
  };

  return (
    <div className='addKnowledgeModalContent'>
      {contextHolder}
      <div className='addKnowledgeDescription'>{t('add_knowledge_description')}</div>
      <textarea
        className='addKnowledgeTextArea'
        placeholder={t('add_knowledge_placeholder')}
        onChange={handleTextAreaChange}
      />
      <Button
        disabled={input.length === 0}
        className='addKnowledgeBigButton'
        style={{
          height: '4rem',
          backgroundColor: '#1777FF',
          border: '#1777FF',
          color: 'white',
          opacity: input.length > 0 ? 1 : 0.5,
        }}
        onClick={handleGenerateClick}
      >
        {t('generate_knowledge')}
      </Button>
    </div>
  );
};

export default FirstInputModalBody;
