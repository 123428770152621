import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en/translation.json';
import translationPT from './locales/pt/translation.json';
import translationES from './locales/es/translation.json';

const resources = {
  en: { translation: translationEN },
  pt: { translation: translationPT },
  es: { translation: translationES }
};

// Custom language detector plugin
const normalizeLanguageCode = {
  type: 'languageDetector',
  init: () => { },
  detect: () => {
    let lng = localStorage.getItem('i18nextLng') || navigator.language;
    // Normalize language code (e.g., 'en-US' -> 'en', 'pt-BR' -> 'pt')
    return lng.split('-')[0];
  },
  cacheUserLanguage: (lng) => {
    localStorage.setItem('i18nextLng', lng);
  }
};

i18n
  .use(normalizeLanguageCode)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage'],
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
