import { useMsal } from '@azure/msal-react';
import { Trans, useTranslation } from 'react-i18next';

import Forms from '../../../Template/layoutComponents/Forms';
import { useEffect, useState } from 'react';

import useTemplateState from '../../../Template/atoms/variables';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { CloseCircleOutlined, AppstoreAddOutlined, DeleteOutlined } from '@ant-design/icons';

import { Button, notification } from 'antd';

import { findParentIdsById, removeItemFromTree, updateItemInTree } from '../../../helpers/misc';
import useMaintenanceState from '../../atoms/variables';
import { deleteAssetTreeItem } from '../../helpers/apiCalls';
import useGlobalState from '../../../Template/atoms/variables';
import Input from '../../../Template/layoutComponents/Input';

import './style.css';
import DeleteModal from './components/DeleteModal';

const GeneralTab = ({
  create = async () => {},
  update = async () => {},
  getCategories = async () => {},
  updateCurrentEditingItem = () => {},
  goBackTo,
  setExternalAllCategories,
}) => {
  const { instance } = useMsal();
  const { t } = useTranslation();
  const location = useLocation();
  const { plantId } = useParams();

  const navigate = useNavigate();

  const [notificationApi, contextHolder] = notification.useNotification();

  const {
    setPendingApiCalls,
    setModalTitle,
    setModalStyle,
    setModalContent,
    setModalConfirm,
    setModalOpen,
  } = useTemplateState();
  const {
    chosenPlant,
    setChosenPlant,
    viewDetailsItem,
    departments,
    orgs,
    systems,
    costcenters,
    manufacturers,
    setViewDetailsItem,
  } = useMaintenanceState();

  const [allCategories, setAllCategories] = useState([]);

  const [chosenItem, setChosenItem] = useState({});
  const [editing, setEditing] = useState(false);

  const [halfMain, setHalfMain] = useState(0);
  const [halfCat, setHalfCat] = useState(0);

  useEffect(() => {
    triggerApiCalls();
  }, []);

  useEffect(() => {
    setChosenItem(viewDetailsItem);
  }, [viewDetailsItem]);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['getCategories']));

      const response = await getCategories(instance);
      setAllCategories(response);
      if (setExternalAllCategories) setExternalAllCategories(response);
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
      console.log(err);
    } finally {
      setPendingApiCalls((current) => current?.filter((item) => item !== 'getCategories'));
    }
  };

  useEffect(() => {
    setHalfMain(Math.ceil(chosenItem?.properties?.filter((prop) => prop.main).length / 2));
    setHalfCat(Math.ceil(chosenItem?.properties?.filter((prop) => !prop.main).length / 2));
  }, [chosenItem]);

  const disableCreateSave = () => {
    return (
      !editing ||
      chosenItem?.properties.some(
        (prop) => prop.required === true && (prop.value === '' || prop.value === null),
      )
    );
  };

  const openDeleteModal = () => {
    setModalTitle(t('delete_tree_item'));
    setModalStyle({
      padding: '2rem',
      gap: '1rem',
      maxHeight: '100%',
    });
    setModalContent(<DeleteModal chosenItem={chosenItem} handleDelete={handleDelete} />);
    setModalConfirm(null);
    setModalOpen(true);
  };

  const handleDelete = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['deleteAssetTreeItem']));
      await deleteAssetTreeItem(instance, chosenItem);

      const newPlant = removeItemFromTree(chosenPlant, chosenItem?.id);

      if (newPlant) {
        setChosenPlant(newPlant);
        navigate(goBackTo);
      } else {
        setChosenPlant(null);
        navigate('/maintenance/plants');
      }
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
      console.log(err);
    } finally {
      setPendingApiCalls((current) => current?.filter((item) => item !== 'deleteAssetTreeItem'));
    }
  };

  return (
    <div className='generalTab'>
      {contextHolder}
      <div className='propsForm mainProps'>
        <div
          className='columnTitle'
          style={{
            paddingBottom: '2rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {t('main_properties')}
          {(location.pathname.includes('assets') || location.pathname.includes('positions')) && (
            <Button
              size='large'
              icon={<DeleteOutlined />}
              style={{
                borderColor: '#FF4D4F',
                background: '#FF4D4F',
                color: 'white',
              }}
              onClick={openDeleteModal}
            >
              {t('delete')}
            </Button>
          )}
        </div>

        <div className='propsFormContent'>
          <div className='propsColumn firstColumn'>
            <Forms
              id={`${chosenItem?.id}-main-props`}
              inputs={chosenItem?.properties?.filter((prop) => prop.main).slice(0, halfMain)}
              onInputChange={(event, inputIndex) => {
                setChosenItem((current) => {
                  if (current) {
                    return updateCurrentEditingItem(
                      setEditing,
                      allCategories,
                      current,
                      inputIndex,
                      event,
                    );
                  }
                  return current;
                });
              }}
              categories={allCategories?.filter((cat) => {
                if (chosenItem?.type) return cat?.type === chosenItem?.type;
                else return cat;
              })}
              departments={departments}
              orgs={orgs}
              systems={systems}
              manufacturers={manufacturers}
              costcenters={costcenters}
            />
          </div>
          {chosenItem?.properties?.filter((prop) => prop.main).slice(halfMain).length > 0 && (
            <div className='propsColumn secondColumn'>
              <Forms
                id={`${chosenItem?.id}-cat-props`}
                inputs={chosenItem?.properties?.filter((prop) => prop.main).slice(halfMain)}
                onInputChange={(event, inputIndex) => {
                  setChosenItem((current) => {
                    if (current) {
                      return updateCurrentEditingItem(
                        setEditing,
                        allCategories,
                        current,
                        +halfMain + inputIndex,
                        event,
                      );
                    }
                    return current;
                  });
                }}
                categories={allCategories?.filter((cat) => {
                  if (chosenItem?.type) return cat?.type === chosenItem?.type;
                  else return cat;
                })}
                departments={departments}
                orgs={orgs}
                systems={systems}
                costcenters={costcenters}
                manufacturers={manufacturers}
              />
            </div>
          )}
        </div>
      </div>
      {chosenItem?.properties?.filter((prop) => !prop.main).length > 0 && (
        <div className='propsForm catProps'>
          <div className='columnTitle sticky'>{t('cat_properties')}</div>
          <div className='propsFormContent'>
            <div className='propsColumn firstColumn'>
              <Forms
                id={`${chosenItem?.id}-cat-props`}
                inputs={chosenItem?.properties?.filter((prop) => !prop.main).slice(0, halfCat)}
                onInputChange={(event, inputIndex) => {
                  setChosenItem((current) => {
                    if (current) {
                      return updateCurrentEditingItem(
                        setEditing,
                        allCategories,
                        current,
                        chosenItem?.properties?.filter((prop) => prop.main).length + inputIndex,
                        event,
                      );
                    }
                    return current;
                  });
                }}
              />
            </div>
            {chosenItem?.properties?.filter((prop) => !prop.main).slice(halfCat).length > 0 && (
              <div className='propsColumn secondColumn'>
                <Forms
                  id={`${chosenItem?.id}-cat-props`}
                  inputs={chosenItem?.properties?.filter((prop) => !prop.main).slice(halfCat)}
                  onInputChange={(event, inputIndex) => {
                    setChosenItem((current) => {
                      if (current) {
                        return updateCurrentEditingItem(
                          setEditing,
                          allCategories,
                          current,
                          chosenItem?.properties?.filter((prop) => prop.main).length +
                            halfCat +
                            inputIndex,
                          event,
                        );
                      }
                      return current;
                    });
                  }}
                />
              </div>
            )}
          </div>
        </div>
      )}

      {viewDetailsItem && (
        <div className='manageCategoriesButtonsContainer detailPage'>
          <Button
            id='cancel'
            size='large'
            icon={<CloseCircleOutlined />}
            style={{
              borderColor: 'black',
              background: 'white',
              color: 'black',
            }}
            onClick={() => {
              navigate(goBackTo);
            }}
          >
            {t('cancel_changes')}
          </Button>

          {chosenItem && (
            <Button
              id='create'
              className={disableCreateSave() ? 'disabled' : ''}
              size='large'
              disabled={disableCreateSave()}
              style={{
                borderColor: '#1777FF',
                background: '#1777FF',
                color: 'white',
              }}
              icon={<AppstoreAddOutlined />}
              onClick={async () => {
                if (viewDetailsItem?.id?.includes('new')) {
                  // SEND ITEM TO BFF (CREATE)
                  try {
                    setPendingApiCalls((current) => current.concat(['create']));

                    const additionalInfo = location.pathname.includes('interconnections')
                      ? {
                          location: plantId,
                        }
                      : {};

                    const mongoId = await create(instance, { ...chosenItem, ...additionalInfo });

                    navigate(`${goBackTo}/${mongoId._id ?? mongoId}`);
                    setViewDetailsItem({ ...chosenItem, id: mongoId });
                  } catch (err) {
                    // API fail notification
                    notificationApi.error({
                      message: 'Falha ao atualizar item',
                      description: err?.response?.data?.message
                        ? `${err?.response?.data?.message} (${err?.response?.status})`
                        : err?.message,
                      duration: 5,
                    });
                  } finally {
                    setPendingApiCalls((current) => current?.filter((item) => item !== 'create'));
                  }
                } else {
                  // SEND ITEM TO BFF (UPDATE)

                  if (
                    location.pathname.includes('positions') ||
                    location.pathname.includes('assets')
                  ) {
                    setChosenPlant((current) => {
                      const newTree = updateItemInTree({ ...current }, chosenItem.id, chosenItem);
                      return newTree;
                    });
                  }

                  try {
                    setPendingApiCalls((current) => current.concat(['update']));

                    const additionalInfo =
                      location.pathname.includes('positions') ||
                      location.pathname.includes('assets')
                        ? {
                            location: plantId,
                            parent: findParentIdsById(chosenPlant, chosenItem.id).pop(),
                            ancestors: findParentIdsById(chosenPlant, chosenItem.id),
                          }
                        : location.pathname.includes('interconnections')
                          ? { location: plantId }
                          : {};

                    await update(instance, {
                      ...chosenItem,
                      ...additionalInfo,
                    });
                    setViewDetailsItem({ ...chosenItem, ...additionalInfo });
                  } catch (err) {
                    // API fail notification
                    notificationApi.error({
                      message: 'Falha ao atualizar item',
                      description: err?.response?.data?.message
                        ? `${err?.response?.data?.message} (${err?.response?.status})`
                        : err?.message,
                      duration: 5,
                    });
                  } finally {
                    setPendingApiCalls((current) => current?.filter((item) => item !== 'update'));
                  }
                }

                setEditing(false);
              }}
            >
              {t('save_item_settings')}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default GeneralTab;
