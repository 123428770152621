import { Button, notification } from 'antd';
import Table from '../../../../../Template/layoutComponents/Table';

import { PlusOutlined } from '@ant-design/icons';

import './style.css';
import { getWoAndUniques } from '../../../../helpers/apiCalls';
import { useMsal } from '@azure/msal-react';
import useTemplateState from '../../../../../Template/atoms/variables';
import useMaintenanceState from '../../../../atoms/variables';
import { useEffect, useState } from 'react';
import TableTip from '../../../../../Template/layoutComponents/Table/components/tip';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { parseIsoDateString, parseIsoDateStringWithTime } from '../../../../../helpers/misc';
import NewWoModal from '../../../../components/NewWoModal';

const WorkOrders = () => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const { instance } = useMsal();

  const { plantId } = useParams();

  const {
    setPendingApiCalls,
    setModalOpen,
    setModalConfirm,
    setModalContent,
    setModalTitle,
    setCleanOnCancel,
    setModalStyle,
  } = useTemplateState();
  const { setViewDetailsItem } = useMaintenanceState();
  const [notificationApi, contextHolder] = notification.useNotification();

  const [wos, setWos] = useState({ items: [], totalItems: 0 });

  const [forceReload, setForceReload] = useState(false);

  useEffect(() => {
    triggerApiCalls();
  }, []);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['getWoAndUniques']));

      const responses = await Promise.all([
        getWoAndUniques(instance, {
          location: plantId,
          limit: 10,
          sort: `-scheduled_date`,
        }),
      ]);
      const woReponse = responses[0];

      setWos(woReponse);
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
      console.log(err);
    } finally {
      setPendingApiCalls((current) => current.filter((item) => item !== 'getWoAndUniques'));
    }
  };

  return (
    <div className='woContent'>
      {contextHolder}
      <div className='woContentHeader'>
        <div className='pageTitle'>{t('work_orders')}</div>
        <div className='pageButtons'>
          <Button
            className='newItemButton'
            size='large'
            icon={<PlusOutlined />}
            style={{
              borderColor: '#1777FF',
              background: '#1777FF',
              color: 'white',
            }}
            onClick={async () => {
              setModalTitle(t('new_wo'));
              setModalContent(<NewWoModal setForceReload={setForceReload} />);
              setModalStyle({});
              setCleanOnCancel(true);
              setModalConfirm(null);
              setModalOpen(true);
            }}
          >
            {t('generate_workorder')}
          </Button>
        </div>
      </div>
      <Table
        startingSort={{ column: 'scheduled_date', asc: false }}
        handleSelect={null}
        handleRowClick={(row) => {
          setViewDetailsItem({ ...row, id: row.id });
          navigate(`/maintenance/plants/${plantId}/work-orders/${row._id}`);
        }}
        apiCall={async (instance, queryParams) => {
          const response = await getWoAndUniques(instance, {
            location: plantId,
            ...queryParams,
          });

          return {
            ...response,
            items: response.items.map((item) => ({
              ...item,
              company: item?.employee?.properties?.find((prop) => prop.key === 'company')?.value,
            })),
          };
        }}
        items={wos?.items.map((item) => ({
          ...item,
          company: item?.employee?.properties?.find((prop) => prop.key === 'company')?.value,
        }))}
        tableSettings={{
          totalItems: wos.totalItems ?? 0,
          columnsConfig: {
            token: {
              label: t('token'),
            },
            company: {
              label: t('contractor'),
            },
            employee: {
              label: t('executor'),
              customElement: (item) =>
                item.employee?.properties?.find((prop) => prop.key === 'name')?.value,
            },
            scheduled_date: {
              label: t('date'),
              replaceValue: (value) => {
                return {
                  label: parseIsoDateString(new Date(value).toISOString()),
                  id: value,
                };
              },
            },
            status: {
              label: t('status'),
              customElement: (item) => <TableTip children={item.status ?? item} />,
            },
          },
          sortable: ['scheduled_date', 'status'],
          listFilter: ['status'],
          searchFilter: ['token'],
          uniqueValues: wos.uniqueValues,
        }}
        forceReload={forceReload}
        setForceReload={setForceReload}
      />
    </div>
  );
};

export default WorkOrders;
