import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Spin, Layout } from 'antd';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import SiderComponent from '../layoutComponents/Sider';
import HeaderComponent from '../layoutComponents/Header';
import { scopes } from '../../auth/config';
import useGlobalState from '../atoms/variables';
import { Modal } from '../layoutComponents/Modal';
import VirtualEngineerButtons from '../../VirtualEnginner/components/VirtualEngineerButtons';
import NeedHelpButton from '../layoutComponents/Header/components/NeedHelp';

import './style.css';
import AppBar from '../layoutComponents/AppBar';
const { Content } = Layout;

const LoadingPage = () => <Spin spinning={true} fullscreen />;

export default function ProtectedPage() {
  const { loading, setLoading, pendingApiCalls, companyInfo, setButtons } = useGlobalState();
  const location = useLocation();

  const isMobile = window.screen.width < 980;

  // Definir o state com a URL atual para redirecionamento pós-login
  const authState = encodeURIComponent(
    JSON.stringify({ redirectUrl: location.pathname + location.search }),
  );

  const authenticationRequest = {
    scopes,
    state: authState,
  };

  useEffect(() => {
    if (pendingApiCalls.length > 0) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [pendingApiCalls]);

  useEffect(() => {
    setButtons((current) => {
      let tmp = current ? [...current] : [];

      if (location.pathname.includes('equipments')) {
        if (!tmp?.some((button) => button.id === 'VirtualEngineerButtons'))
          tmp = (tmp || []).concat([
            { id: 'VirtualEngineerButtons', node: <VirtualEngineerButtons /> },
          ]);
      } else {
        tmp = tmp?.filter((button) => button.id != 'VirtualEngineerButtons');
      }
      return tmp;
    });
  }, [location]);

  useEffect(() => {
    if (companyInfo?.needHelp) {
      setButtons((current) =>
        current?.some((button) => button.id === 'NeedHelp')
          ? current
          : (current || []).concat([{ id: 'NeedHelp', node: <NeedHelpButton /> }]),
      );
    }
  }, [companyInfo]);

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authenticationRequest}
      loadingComponent={LoadingPage}
    >
      <Layout id='layout'>
        {loading && <LoadingPage />}
        <Modal title='Selecione o equipamento para começar' />
        <SiderComponent />
        <Layout className='border-content'>
          <HeaderComponent />
          <React.Suspense fallback={<Spin spinning={true} fullscreen />}>
            <Content id='content-outlet' style={isMobile ? { paddingBottom: '5rem' } : {}}>
              <Outlet />
              {isMobile && <AppBar />}
            </Content>
          </React.Suspense>
        </Layout>
      </Layout>
    </MsalAuthenticationTemplate>
  );
}
