import { useNavigate, useParams } from 'react-router-dom';
import useGlobalState from '../../../../Template/atoms/variables';
import { useEffect, useState } from 'react';
import {
  addItemToTree,
  findObjPath,
  findParentIdsById,
  removeItemFromTree,
  updateItemInTree,
} from '../../../../helpers/misc';

import {
  PlusOutlined,
  CloseCircleOutlined,
  AppstoreAddOutlined,
  DeleteFilled,
  DeleteOutlined,
} from '@ant-design/icons';

import DoubleColumnTemplate from '../../../../Template/layoutComponents/DoubleColumn/index';
import Input from '../../../../Template/layoutComponents/Input/index';
import CustomOrderByButton from '../../../../Template/layoutComponents/OrderButton/index';

import search from '../../../../Settings/images/search.svg';

import viewDetails from '../../../images/viewDetails.svg';

import { Button, notification } from 'antd';
import useSettingsState from '../../../../Settings/atoms/variables';
import Forms from '../../../../Template/layoutComponents/Forms';
import useMaintenanceState from '../../../atoms/variables';
import AssetTree from '../../../components/AssetTree';

import { concatChildren } from '../../../../helpers/misc';
import { useMsal } from '@azure/msal-react';

import { getNewItem, getNewPlant } from '../../../helpers/schemas';
import { getAllCategories, getHierarchy } from '../../../../Settings/helpers/apiCalls';
import {
  deleteAssetTreeItem,
  newAssetTreeItem,
  updateAssetTreeItem,
} from '../../../helpers/apiCalls';
import { useTranslation } from 'react-i18next';

import './style.css';
import DeleteModal from '../../../components/GeneralTab/components/DeleteModal';

const Assets = () => {
  const { instance } = useMsal();
  const { plantId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [notificationApi, contextHolder] = notification.useNotification();
  const {
    setPendingApiCalls,
    setModalTitle,
    setModalStyle,
    setModalContent,
    setModalConfirm,
    setModalOpen,
  } = useGlobalState();
  const { chosenPositions, setChosenPositions } = useSettingsState();

  const {
    existingAssetTrees,
    chosenPlant,
    setChosenPlant,
    setExistingAssetTrees,
    departments,
    orgs,
    systems,
    manufacturers,
    setViewDetailsItem,
    costcenters,
  } = useMaintenanceState();
  const [chosenLevel, setChosenLevel] = useState(null);
  const [possibleChild, setPossibleChild] = useState(null);

  const [creating, setCreating] = useState(false);
  const [editing, setEditing] = useState(false);

  const [newItem, setNewItem] = useState(null);

  const [allCategories, setAllCategories] = useState([]);

  const [openedLevels, setOpenedLevels] = useState([]);
  const [searchString, setSearchString] = useState(null);
  const [asc, setAsc] = useState(true);

  useEffect(() => {
    if (chosenPlant?.id !== 'newPlant')
      setExistingAssetTrees((current) => [
        chosenPlant,
        ...current?.filter((plant) => plant?.id !== chosenPlant?.id),
      ]);
  }, [chosenPlant]);

  useEffect(() => {
    if (plantId && existingAssetTrees) {
      if (plantId == 'newPlant') {
        setChosenLevel(getNewPlant(t));
        setEditing(true);
      }
      triggerApiCalls();
    } else {
      navigate('/maintenance/plants');
    }
  }, []);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['getAllCategories', 'getChosenPositions']));

      const responses = await Promise.all([getAllCategories(instance), getHierarchy(instance)]);

      setAllCategories(responses[0]);
      setChosenPositions(responses[1]);
    } catch (err) {
      // API fail notification
      notificationApi.error({
        message: 'Não foi possível recuperar todas as informações necessárias',
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
    } finally {
      setPendingApiCalls((current) =>
        current.filter((item) => item !== 'getAllCategories' && item !== 'getChosenPositions'),
      );
    }
  };

  useEffect(() => {
    if (chosenPositions && chosenLevel) {
      const possibleChild = checkPossibleChild();
      setPossibleChild(possibleChild);

      setNewItem(getNewItem(t, possibleChild, chosenLevel));
    }
  }, [chosenPositions, chosenLevel, t]);

  useEffect(() => {
    setExistingAssetTrees((current) => {
      if (current?.length === 0) {
        return current.concat([chosenPlant]);
      }

      const indexToChange = current?.findIndex((plant) => plant?.id === chosenPlant?.id);
      let tmp;
      if (current && indexToChange != -1) {
        tmp = [...current];
        tmp[indexToChange] = chosenPlant;
        return tmp;
      }
      return current;
    });
  }, [chosenPlant]);

  const checkPossibleChild = () => {
    const currentLevelIndex = chosenPositions.findIndex(
      (position) => position.type === chosenLevel?.type,
    );
    return currentLevelIndex != -1 ? (chosenPositions?.[currentLevelIndex + 1] ?? false) : false;
  };

  const updateCurrentEditingItem = (current, inputIndex, event) => {
    let chosenCategory;
    // Search by current selected ID
    chosenCategory = allCategories?.find((cat) => {
      return cat.id === event.target.value;
    });

    if (!chosenCategory)
      // If not found, search by selected Categoy in 'Categoria' property
      chosenCategory = allCategories?.find((cat) => {
        const catProp = current.properties.find((prop) => prop?.key === 'category' && prop?.main);

        return cat.id === catProp?.value?.id;
      });

    const chosenCategoryProperties = chosenCategory ? [...chosenCategory?.properties] : [];

    let updateName = false;

    return {
      ...current,
      category: chosenCategory,
      icon: chosenCategoryProperties?.find((prop) => prop.key === 'icon')?.value?.id,
      properties: [
        ...current.properties.map((item, index) => {
          if (index === inputIndex) {
            if (item.key === 'name') {
              updateName = event.target.value;
            }

            return {
              ...item,
              value: item.type.toLowerCase().includes('select')
                ? Array.isArray(event.target.value)
                  ? event.target.value.map((value) => ({
                      id: value,
                      label: '',
                    }))
                  : { id: event.target.value, label: '' }
                : item.type.toLowerCase().includes('bool')
                  ? event.target.value
                  : event.target.value,
              // event.target.value?.replace(/ /g, ''),
            };
          }
          return item;
        }),
      ]
        .filter(
          (prop) =>
            prop.main ||
            chosenCategoryProperties.some((currentCatProp) => currentCatProp.key == prop.key),
        )
        .concat(
          chosenCategory?.properties
            ?.filter((prop) => !prop.main)
            .filter(
              (prop) =>
                !current.properties.some(
                  (currentProp) => !currentProp.main && currentProp.key == prop.key,
                ),
            ) ?? [],
        ),
      name: updateName ? updateName : current.name,
    };
  };

  const disableCreateSave = () => {
    if (creating) {
      return newItem?.properties.some(
        (prop) => prop.required === true && (prop.value === '' || prop.value === null),
      );
    }

    if (editing || chosenLevel.id === 'newPlant') {
      return chosenLevel?.properties.some(
        (prop) => prop.required === true && (prop.value === '' || prop.value === null),
      );
    }

    return true;
  };

  const openDeleteModal = () => {
    setModalTitle(t('delete_tree_item'));
    setModalStyle({
      padding: '2rem',
      gap: '1rem',
      maxHeight: '100%',
    });
    setModalContent(<DeleteModal chosenItem={chosenLevel} handleDelete={handleDelete} />);
    setModalConfirm(null);
    setModalOpen(true);
  };

  const handleDelete = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['deleteAssetTreeItem']));
      await deleteAssetTreeItem(instance, chosenLevel);

      const newPlant = removeItemFromTree(chosenPlant, chosenLevel?.id);

      if (newPlant) {
        setChosenLevel(null);
        setChosenPlant(newPlant);
      } else {
        setChosenPlant(null);
        navigate('/maintenance/plants');
      }

      setModalOpen(false);
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
      console.log(err);
    } finally {
      setPendingApiCalls((current) => current?.filter((item) => item !== 'deleteAssetTreeItem'));
    }
  };

  return (
    <div id='assets'>
      {contextHolder}
      <DoubleColumnTemplate
        noBorder={true}
        customColumnGrow={[2, 2]}
        columnColors={['white', '#FBFBFB']}
        firstColumnContent={
          <div className='columnContainer' style={{ gap: '1rem', overflow: 'auto' }}>
            <div className='columnHeader'>
              <div className='columnTitle'>{t('asset_tree')}</div>
              <div className='controls'>
                {false && (
                  <Input
                    value={searchString ?? ''}
                    onChange={(event) => {
                      setSearchString(event.target.value ?? null);
                    }}
                    placeholder={t('search')}
                    icon={<img src={search} />}
                  />
                )}
                {false && (
                  <CustomOrderByButton
                    onClick={() => {
                      setAsc((current) => !current);
                    }}
                  />
                )}
                <Button
                  disabled={!chosenLevel || !possibleChild || creating || editing}
                  icon={<PlusOutlined />}
                  size='large'
                  style={{
                    height: '3rem',
                    background:
                      !chosenLevel || !possibleChild || creating || editing ? 'grey' : 'black',
                    color: 'white',
                  }}
                  onClick={() => {
                    if (possibleChild) {
                      setOpenedLevels((current) => {
                        return [...current, chosenLevel];
                      });
                      setCreating(true);
                    }
                  }}
                >
                  {possibleChild
                    ? `${t('create_child')} ${t(possibleChild?.type)}`
                    : possibleChild == false
                      ? t('last_level')
                      : t('select_level')}
                </Button>
              </div>
            </div>
            <div className='assetTreeContainer'>
              {chosenPlant && (
                <AssetTree
                  possibleChild={possibleChild}
                  assetTree={chosenPlant || chosenLevel}
                  onNodeClick={(item) => {
                    setOpenedLevels((current) => {
                      if (current.some((opened) => opened?.id === item?.id)) {
                        return [
                          ...current.filter(
                            (opened) =>
                              opened?.id !== item?.id &&
                              !concatChildren(item?.children)?.some(
                                (child) => opened?.id === child?.id,
                              ),
                          ),
                        ];
                      } else {
                        return [...current, item];
                      }
                    });
                    setChosenLevel(item);
                  }}
                  isPlant={true}
                  isAsset={null}
                  chosenLevel={chosenLevel}
                  creating={creating}
                  disabled={creating || editing}
                  openedLevels={openedLevels}
                />
              )}
            </div>
          </div>
        }
        secondColumnContent={
          <div
            className='columnContainer'
            style={{
              gap: '1rem',
              paddingBottom: 0,
              backgroundColor: 'rgb(251, 251, 251)',
              borderLeft: '1px solid #ebecee',
              overflow: 'auto',
            }}
          >
            <div className='columnHeader'>
              <div className='columnTitle'>
                {creating
                  ? `${t('creating_child')} ${possibleChild?.label}`
                  : chosenLevel
                    ? chosenLevel.name
                    : ''}
              </div>
              {chosenLevel && chosenLevel?.id != 'newPlant' && !creating && (
                <div
                  className={`viewDetailsContainer ${editing ? 'disabled' : ''}`}
                  onClick={() => {
                    if (!editing) {
                      setViewDetailsItem({
                        ...chosenLevel,
                        category:
                          chosenLevel?.category ??
                          chosenLevel.properties.find((prop) => prop.key === 'category')?.value?.id,
                        purchase_date:
                          chosenLevel?.purchase_date ??
                          chosenLevel.properties.find((prop) => prop.key === 'purchase_date')
                            ?.value,
                      });
                      navigate(
                        `/maintenance/plants/${plantId}/${['machine', 'assembly', 'equipment', 'component'].includes(chosenLevel?.type) ? 'assets' : 'positions'}/${chosenLevel.id}`,
                      );
                    }
                  }}
                >
                  <div className='viewDetailsIcon'>
                    <img src={viewDetails} />
                  </div>
                  <div className='viewDetailsText'>{t('view_details')}</div>
                </div>
              )}
            </div>
            <div
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Forms
                id={creating ? newItem?.id : chosenLevel?.id}
                inputs={creating ? newItem?.properties : (chosenLevel?.properties ?? [])}
                onInputChange={(event, inputIndex) => {
                  if (!creating) {
                    setEditing(true);
                  }
                  if (creating) {
                    setNewItem((current) => {
                      if (current) {
                        return updateCurrentEditingItem(current, inputIndex, event);
                      }
                      return current;
                    });
                  } else {
                    setChosenLevel((current) => {
                      if (current) {
                        return updateCurrentEditingItem(current, inputIndex, event);
                      }
                      return current;
                    });
                  }
                }}
                categories={allCategories.filter((cat) => {
                  if (creating) {
                    return cat?.type === possibleChild?.type;
                  } else {
                    return cat?.type === chosenLevel?.type;
                  }
                })}
                departments={departments}
                costcenters={costcenters}
                orgs={orgs}
                systems={systems?.filter((s) => s?.parent === plantId)}
                manufacturers={manufacturers}
              />
              <div className='manageLevelButtonsContainer'>
                {!creating && !editing && chosenLevel && (
                  <Button
                    icon={<DeleteOutlined />}
                    size='large'
                    style={{
                      borderColor: '#ff4d4f ',
                      background: '#ff4d4f ',
                      color: 'white',
                    }}
                    onClick={() => {
                      openDeleteModal();
                    }}
                  >
                    {t('delete')}
                  </Button>
                )}

                {(creating || editing) && (
                  <Button
                    id='cancel'
                    size='large'
                    icon={<CloseCircleOutlined />}
                    style={{
                      borderColor: 'black',
                      background: 'white',
                      color: 'black',
                    }}
                    onClick={() => {
                      setCreating(false);
                      setEditing(false);
                      setNewItem(getNewItem(t, possibleChild, chosenLevel));
                      setChosenLevel(null);

                      if (chosenLevel.id === 'newPlant') {
                        navigate('/maintenance/plants');
                      }
                    }}
                  >
                    Cancelar
                  </Button>
                )}

                {chosenLevel && (
                  <Button
                    id='create'
                    size='large'
                    className={disableCreateSave() ? 'disabled' : ''}
                    disabled={disableCreateSave()}
                    style={{
                      borderColor: '#1777FF',
                      background: '#1777FF',
                      color: 'white',
                    }}
                    icon={<AppstoreAddOutlined />}
                    onClick={async () => {
                      if (creating || chosenLevel?.id === 'newPlant') {
                        // SEND ITEM TO BFF (CREATE)
                        try {
                          setPendingApiCalls((current) => current.concat(['newAssetTreeItem']));

                          const mongoId = await newAssetTreeItem(
                            instance,
                            chosenLevel.id === 'newPlant'
                              ? {
                                  ...chosenLevel,
                                  location: null,
                                  parent: null,
                                  ancestors: [],
                                }
                              : {
                                  ...newItem,
                                  location: plantId,
                                  parent: chosenLevel.id,
                                  ancestors: [
                                    ...findParentIdsById(chosenPlant, chosenLevel.id),
                                    chosenLevel.id,
                                  ],
                                },
                          );

                          const path = findObjPath(chosenPlant, chosenLevel?.id, 'id');

                          setOpenedLevels((current) => {
                            return [...current, chosenLevel];
                          });

                          setChosenPlant((current) => {
                            return chosenLevel.id === 'newPlant'
                              ? {
                                  ...chosenLevel,
                                  id: mongoId,
                                  parent: null,
                                }
                              : addItemToTree({ ...current }, path, {
                                  ...newItem,
                                  id: mongoId,
                                  location: plantId,
                                  ancestors: [
                                    ...findParentIdsById(chosenPlant, chosenLevel.id),
                                    chosenLevel.id,
                                  ],
                                });
                          });

                          setChosenLevel(
                            chosenLevel.id === 'newPlant'
                              ? {
                                  ...chosenLevel,
                                  id: mongoId,
                                  parent: null,
                                }
                              : {
                                  ...newItem,
                                  id: mongoId,
                                  location: plantId,
                                  ancestors: [
                                    ...findParentIdsById(chosenPlant, chosenLevel.id),
                                    chosenLevel.id,
                                  ],
                                },
                          );

                          if (chosenLevel.id === 'newPlant')
                            navigate(`/maintenance/plants/${mongoId}`);
                        } catch (err) {
                          // API fail notification
                          notificationApi.error({
                            message: 'Falha ao criar novo item',
                            description: err?.response?.data?.message
                              ? `${err?.response?.data?.message} (${err?.response?.status})`
                              : err?.message,
                            duration: 5,
                          });
                        } finally {
                          setPendingApiCalls((current) =>
                            current.filter((item) => item !== 'newAssetTreeItem'),
                          );
                        }
                      } else {
                        setChosenPlant((current) => {
                          const newTree = updateItemInTree(
                            { ...current },
                            chosenLevel.id,
                            chosenLevel,
                          );

                          return newTree;
                        });

                        // SEND ITEM TO BFF (UPDATE)
                        try {
                          setPendingApiCalls((current) => current.concat(['updateAssetTreeItem']));
                          await updateAssetTreeItem(instance, {
                            ...chosenLevel,
                            location: plantId,
                            parent: findParentIdsById(chosenPlant, chosenLevel.id).pop(),
                            ancestors: findParentIdsById(chosenPlant, chosenLevel.id),
                          });
                        } catch (err) {
                          // API fail notification
                          notificationApi.error({
                            message: 'Falha ao atualizar item',
                            description: err?.response?.data?.message
                              ? `${err?.response?.data?.message} (${err?.response?.status})`
                              : err?.message,
                            duration: 5,
                          });
                        } finally {
                          setPendingApiCalls((current) =>
                            current.filter((item) => item !== 'updateAssetTreeItem'),
                          );
                        }
                      }
                      setCreating(false);
                      setEditing(false);
                    }}
                  >
                    {creating ? t('create_item_settings') : t('save_item_settings')}
                  </Button>
                )}
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};
export default Assets;
