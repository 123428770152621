import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useGlobalState from '../../atoms/variables';
import { findOrCreateUser, getCompanyInfo } from '../../helpers/apiCalls';
import { useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';

function DynamicRedirect() {
  const navigate = useNavigate();
  const { setPendingApiCalls, setCompanyInfo, setUser, companyInfo } = useGlobalState();
  const { t } = useTranslation();

  const [notificationApi, contextHolder] = notification.useNotification();

  const { instance } = useMsal();

  useEffect(() => {
    triggerApiCalls();
  }, []);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['getCompanyInfo', 'findOrCreateUser']));
      const responses = await Promise.all([getCompanyInfo(instance), findOrCreateUser(instance)]);
      setCompanyInfo(responses[0]);
      setUser(responses[1]);
    } catch (err) {
      console.log(err);
      // API fail notification
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
    } finally {
      setPendingApiCalls((current) =>
        current.filter((item) => item !== 'getCompanyInfo' && item !== 'findOrCreateUser'),
      );
    }
  };

  useEffect(() => {
    if (companyInfo) {
      // Redireciona para a rota desejada baseada no estado global
      const defaultAccess = companyInfo.defaultAccess || ['/virtual-engineer']; // substitua por lógica apropriada
      navigate(defaultAccess[0], { replace: true });
    }
  }, [companyInfo]);

  // Retorne um placeholder enquanto a lógica de redirecionamento é executada
  return <div>{contextHolder}</div>;
}

export default DynamicRedirect;
