import axios from 'axios';

import { protectedResourcesScopes } from '../../auth/config';
import { acquireToken } from '../../auth/msal.js';

const apiUrl = `${process.env.REACT_APP_BFF_API}`;
const scope = protectedResourcesScopes.core;

export async function getCompanyInfo(instance) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.get(`${apiUrl}/system/company/companyInfo`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function findOrCreateUser(instance) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.get(`${apiUrl}/settings/user/findOrCreate`, {
      headers: { Authorization: `Bearer ${accessToken}` }
    });
    return data;


  } catch (e) {
    throw e;
  }
}


export async function getHistoryItems(instance, queryParams = {}) {
  const accessToken = await acquireToken(instance, scope);
  try {
    const { data } = await axios.get(`${apiUrl}/system/history`, {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: { ...queryParams, uniqueValues: true },
    });

    return data;
  } catch (e) {
    throw e;
  }
}