import { useTranslation } from 'react-i18next';

const AffectedSubjects = ({ subjects = [], type }) => {
  const { t } = useTranslation();

  return (
    <div className='documentPseudoTable'>
      <div className='documentPseudoTableLine header'>
        <div className='pseudoTableLineCell header'>{t('name')}</div>
        <div className='pseudoTableLineCell header'>{t('type')}</div>
      </div>

      {subjects.map((sub) => (
        <div className='documentPseudoTableLine' key={'asd'}>
          <div className='pseudoTableLineCell'>{sub?.name}</div>
          <div className='pseudoTableLineCell'>{type}</div>
        </div>
      ))}
    </div>
  );
};

export default AffectedSubjects;
