import React, { useState, useCallback, useMemo } from 'react';
import { Button } from 'antd';
import Input from '../../Input';
import { useTranslation } from 'react-i18next';

const SearchFilter = ({
  column,
  setFilter,
  setShowFilter,
  setFilterPosition,
  triggerApiCall,
  filter,
}) => {
  const { t } = useTranslation();

  // Melhorar a lógica para encontrar o valor inicial
  const initialValue = useMemo(() => {
    if (column.filter.getFilter) {
      // Usar a chave de valor definida ou procurar por regex
      const valueKey = Object.keys(column.filter.getFilter('test')).find((key) =>
        key.includes('regex'),
      );
      // Retornar o valor apenas se ele não for null/undefined/vazio
      const value = filter?.[valueKey];
      return value !== undefined && value !== null && value !== '' ? value : '';
    }

    const value = filter?.[`${column.id}[regex]`];
    return value !== undefined && value !== null && value !== '' ? value : '';
  }, [column, filter]);

  const [inputValue, setInputValue] = useState(initialValue);

  // Extrair lógica de construção do filtro para função separada
  const buildFilterObject = useCallback(
    (value) => {
      if (column.filter.getFilter) {
        return column.filter.getFilter(value);
      }

      if (!value) {
        return {
          [`${column.id}[regex]`]: undefined,
          [`${column.id}[options]`]: undefined,
        };
      }

      return {
        [`${column.id}[regex]`]: value,
        [`${column.id}[options]`]: 'i',
      };
    },
    [column],
  );

  // Usar constantes para melhorar legibilidade
  const BUTTON_STYLES = {
    clean: {
      borderColor: 'black',
      background: 'white',
      color: 'black',
    },
    filter: {
      borderColor: '#1777FF',
      background: '#1777FF',
      color: 'white',
    },
  };

  const handleSubmit = useCallback(() => {
    const newFilter = buildFilterObject(inputValue);
    let tmpFilter;
    setFilter((current) => {
      tmpFilter = {
        ...current,
        ...newFilter,
      };

      return tmpFilter;
    });
    setShowFilter(null);
    setFilterPosition(null);
    triggerApiCall(tmpFilter); // Passar o novo filtro diretamente
  }, [inputValue, buildFilterObject, setFilter, setShowFilter, setFilterPosition, triggerApiCall]);

  const handleCleanFilter = useCallback(() => {
    const newFilter = buildFilterObject(undefined);
    setFilter((current) => ({
      ...current,
      ...newFilter,
    }));
    setInputValue('');
    triggerApiCall(newFilter); // Passar o novo filtro diretamente
  }, [buildFilterObject, setFilter, triggerApiCall]);

  const handleInputChange = useCallback(
    (e) => {
      const newValue = e.target.value;
      setInputValue(newValue);
      const newFilter = buildFilterObject(newValue || undefined);
      setFilter((current) => ({
        ...current,
        ...newFilter,
      }));
    },
    [buildFilterObject, setFilter],
  );

  const isButtonDisabled = !inputValue || inputValue === '';

  return (
    <div className='opwellTableCellFilter'>
      <div className='filterTitle'>
        {t('search_values')} <b>{column.label}</b>
      </div>

      <Input value={inputValue} placeholder={t('search')} onChange={handleInputChange} />

      <Button
        className={`filterButton ${isButtonDisabled ? 'disabled' : ''}`}
        size='large'
        disabled={isButtonDisabled}
        style={BUTTON_STYLES.clean}
        onClick={handleCleanFilter}
      >
        {t('clean')}
      </Button>

      <Button
        className={`filterButton ${isButtonDisabled ? 'disabled' : ''}`}
        size='large'
        disabled={isButtonDisabled}
        style={BUTTON_STYLES.filter}
        onClick={handleSubmit}
      >
        {t('filter')}
      </Button>
    </div>
  );
};

export default SearchFilter;
