import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CloseCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';

import './style.css';
const MultiInput = ({
  value,
  icon,
  placeholder,
  type,
  style,
  onChange,
  disabled,
  onBlur,
  maxLength,
}) => {
  const { t } = useTranslation();

  const [values, setValues] = useState(value ?? []);
  const [finalValue, setFinalValue] = useState('');

  const [disallowedNames, setDisallowedNames] = useState([
    t('new_category'),
    t('new_property'),
    t('new_plant'),
    t('new_area'),
    t('new_sub_area'),
    t('new_line'),
    t('new_machine'),
    t('new_assembly'),
    t('new_equipment'),
    t('new_component'),
    t('new_instrument'),
    t('new_system'),
    t('new_cost_center'),
    t('new_manufacturer'),
    t('new_organization'),
    t('new_department'),
    t('new_interconnection'),
    t('new_user'),
    t('new_user_group'),
    t('new_executor'),
    'email@email.com',
  ]);

  useEffect(() => {
    setDisallowedNames([
      t('new_category'),
      t('new_property'),
      t('new_plant'),
      t('new_area'),
      t('new_sub_area'),
      t('new_line'),
      t('new_machine'),
      t('new_assembly'),
      t('new_equipment'),
      t('new_component'),
      t('new_instrument'),
      t('new_system'),
      t('new_cost_center'),
      t('new_manufacturer'),
      t('new_organization'),
      t('new_department'),
      t('new_interconnection'),
      t('new_user'),
      t('new_user_group'),
      t('new_executor'),
      'email@email.com',
    ]);
  }, [t]);

  return (
    <div className='inputContainer multi'>
      {icon && <div className='inputIconContainer'>{icon}</div>}
      <div className='currentValueContainer'>
        <input
          disabled={disabled}
          value={
            disallowedNames.some(
              (name) =>
                name.toLocaleLowerCase().replace(/ /g, '') ===
                `${finalValue}`?.toLocaleLowerCase().replace(/ /g, ''),
            )
              ? ''
              : finalValue
          }
          placeholder={placeholder}
          style={{ ...style, paddingLeft: icon ? '3rem' : '' }}
          className={`customInput ${disabled ? 'disabled' : ''}`}
          onChange={(e) => {
            if (type === 'number') {
              if (finalValue.includes('.')) {
                const lastChar = e.target.value.slice(-1);
                if (lastChar === ',' || lastChar === '.') {
                  return;
                }
              }
              setFinalValue(e.target.value.replace(/[^\d\.\,]*/g, '').replace(/\,/g, '.'));
            } else {
              setFinalValue(e.target.value);
            }
          }}
          maxLength={maxLength}
          onBlur={onBlur ? onBlur : () => {}}
        />
        <div
          className='addIcon'
          onClick={() => {
            if (
              finalValue &&
              !disallowedNames.some(
                (name) =>
                  name.toLocaleLowerCase().replace(/ /g, '') ===
                  `${finalValue}`?.toLocaleLowerCase().replace(/ /g, ''),
              ) &&
              !values.includes(finalValue)
            ) {
              const newValue =
                type === 'number'
                  ? values
                      .concat([finalValue])
                      .map((v) => v.replace(/[^\d\.\,]*/g, '').replace(/\,/g, '.'))
                  : values.concat([finalValue]);

              onChange(
                newValue.length > 0 ? { target: { value: newValue } } : { target: { value: null } },
              );

              setValues((current) => current.concat([finalValue]));
            }
            setFinalValue('');
          }}
        >
          <PlusCircleOutlined style={{ fontSize: ' 1.5rem', color: '#494949' }} />
        </div>
      </div>

      {values.map((v) => (
        <div className='previousValueContainer'>
          <input className={`customInput disabled`} disabled={true} value={v} />
          <div
            className={`removeIcon ${disabled ? 'disabled' : ''}`}
            onClick={() => {
              if (!disabled) {
                const newValue =
                  type === 'number'
                    ? values
                        .filter((c) => c != v)
                        .map((v) => v.replace(/[^\d\.\,]*/g, '').replace(/\,/g, '.'))
                    : values.filter((c) => c != v);

                onChange(
                  newValue.length > 0
                    ? { target: { value: newValue } }
                    : { target: { value: null } },
                );
                setValues((current) => current.filter((c) => c != v));
              }
            }}
          >
            <CloseCircleOutlined style={{ fontSize: ' 1.5rem', color: '#494949' }} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default MultiInput;
