import { PlusOutlined } from '@ant-design/icons';
import { Button, Modal, message } from 'antd';
import { useTranslation } from 'react-i18next';

import { useMsal } from '@azure/msal-react';
import useGlobalState from '../../../../../Template/atoms/variables';
import FirstInputModalBody from './components/FirstInputModal';

import './style.css';

const AddKnowledge = () => {
  const { setModalOpen, setModalContent, setModalStyle, setModalTitle, setModalConfirm } =
    useGlobalState();

  const { instance } = useMsal();
  const { t } = useTranslation();

  const showModal = () => {
    setModalTitle(t('add_knowledge_title'));
    setModalContent(<FirstInputModalBody showModal={showModal} />);
    setModalStyle({ padding: '2rem', gap: '1rem', minHeight: '70%', minWidth: '55%' });
    setModalConfirm(null);
    setModalOpen(true);
  };

  return (
    <Button
      size='large'
      icon={<PlusOutlined />}
      style={{
        background: 'black',
        color: 'white',
        border: 'black',
      }}
      className='addKnowledgeButton'
      onClick={showModal}
    >
      {t('add_knowledge')}
    </Button>
  );
};

export default AddKnowledge;
