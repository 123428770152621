import assetTreeNodeArrowClosed from '../../images/assetTreeNodeArrowClosed.svg';
import assetTreeNodeArrowOpened from '../../images/assetTreeNodeArrowOpened.svg';

import { CloseCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';

import icons from '../AssetTreeIcons/index';

import './style.css';
import { useTranslation } from 'react-i18next';

const AssetTree = ({
  assetTree,
  onNodeClick,
  isPlant,
  isAsset,
  systemPage,
  creating,
  possibleChild,
  disabled,
  chosenLevel,
  openedLevels,
  handleAddClick,
  children,
}) => {
  const { t } = useTranslation();

  const assetTypes = ['machine', 'assembly', 'equipment', 'component'];

  return (
    <div className='assetTreeNode'>
      <div
        style={systemPage ? { cursor: 'pointer' } : {}}
        className={`assetTreeNodeHeader ${(creating && chosenLevel?.id !== assetTree?.id) || disabled || (systemPage && !assetTypes.includes(assetTree?.type)) ? 'disabled' : ''} ${isPlant ? 'plant' : ''}`}
        onClick={() => {
          if (!disabled) onNodeClick(assetTree);
        }}
      >
        <div
          className='assetTreeNodeArrow'
          style={assetTree?.children?.length > 0 ? {} : { opacity: '40%' }}
        >
          <img
            src={
              openedLevels.some((opened) => opened?.id === assetTree?.id)
                ? assetTree?.children?.length > 0
                  ? assetTreeNodeArrowOpened
                  : creating && chosenLevel?.id === assetTree?.id
                    ? assetTreeNodeArrowOpened
                    : assetTreeNodeArrowClosed
                : assetTreeNodeArrowClosed
            }
          />
        </div>

        <div
          className={`assetTreeNodeHeaderContent ${chosenLevel?.id === assetTree?.id ? 'selected' : ''}`}
        >
          <div className='assetTreeNodeTitleContainer'>
            <div className='assetTreeNodeIcon'>
              {icons.find((icon) => icon.type == assetTree?.type)?.icon}
            </div>
            <div className='assetTreeNodeTitle'>
              {assetTree?.id === 'newPlant' ? t('new_plant') : assetTree?.name}
            </div>
          </div>
          {isAsset && (
            <div
              className='addToSystemContainer'
              onClick={(e) => {
                e.stopPropagation();
                handleAddClick(assetTree);
              }}
            >
              {children?.some((child) => child === assetTree.id) ? (
                <CloseCircleOutlined style={{ fontSize: ' 1.5rem', color: '#FF4D4F' }} />
              ) : (
                <PlusCircleOutlined style={{ color: '#686978' }} />
              )}
            </div>
          )}
        </div>
      </div>
      <div
        className={`assetTreeNodeContent ${openedLevels?.some((opened) => opened?.id === assetTree?.id) ? 'opened' : 'closed'}`}
      >
        {(assetTree?.children?.length > 0 || (creating && chosenLevel?.id === assetTree?.id)) && (
          <div className={`assetTreeNodeContentLineContainer`}>
            <div className='assetTreeNodeContentLine'></div>
          </div>
        )}
        <div className='childAssetTree'>
          {creating && chosenLevel?.id === assetTree?.id && (
            <div className='assetTreeNode'>
              <div className={`assetTreeNodeHeader`}>
                <div className='assetTreeNodeArrow' style={{ opacity: '40%' }}>
                  <img src={assetTreeNodeArrowClosed} />
                </div>

                <div className={`assetTreeNodeHeaderContent`}>
                  <div className='assetTreeNodeTitleContainer'>
                    <div className='assetTreeNodeIcon'>
                      {icons.find((icon) => icon.type == possibleChild?.type)?.icon}
                    </div>
                    <div className='assetTreeNodeTitle creating'>{`${t('creating_child')} ${t(possibleChild?.type.replace('-', '_'))}`}</div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {assetTree?.children?.map((child) => {
            return (
              <AssetTree
                assetTree={child}
                onNodeClick={onNodeClick}
                isPlant={false}
                systemPage={systemPage}
                isAsset={isAsset === null ? null : assetTypes.includes(child?.type)}
                handleAddClick={handleAddClick}
                children={children}
                creating={creating}
                possibleChild={possibleChild}
                disabled={disabled}
                chosenLevel={chosenLevel}
                openedLevels={openedLevels}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AssetTree;
