import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

const LoadingModal = () => {
  const { t } = useTranslation();
  return (
    <div className='addKnowledgeModalContent'>
      <div className='addKnowledgeLoading'>
        <Spin percent={'auto'} size='large' />
        <div className='addKnowledgeLoadingText'>{t('generating_knowledge')}</div>
      </div>
    </div>
  );
};

export default LoadingModal;
