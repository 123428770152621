import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import arrowDown from '../../images/arrowDown.svg';
import { createPortal } from 'react-dom';

import './style.css';
import { isDivOutOfViewport } from '../../../helpers/misc';

const SingleSelect = ({ shouldOpenUp, options, value, style, onChange, disabled }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(value?.id);
  const [openAbove, setOpenAbove] = useState(false);
  const [dropdownStyles, setDropdownStyles] = useState({
    top: 0,
    left: 0,
    width: 0,
  });

  const { t } = useTranslation();
  const dropdownRef = useRef(null);
  const listRef = useRef(null);

  const handleSingleSelectBehaviour = (value) => {
    setSelected(value);
    onChange({ target: { value: value } });
    setIsOpen(false);
    setOpenAbove(false);
  };

  const handleOpen = (shouldOpenAbove) => {
    if (!disabled) {
      const rect = dropdownRef.current.getBoundingClientRect();

      setIsOpen(true);
      // Precisamos setar os estilos iniciais para o dropdown aparecer e podermos medir sua altura
      setDropdownStyles({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
        width: rect.width,
      });

      // Aguardar o próximo ciclo de renderização para medir a altura
      setTimeout(() => {
        const dropdownElement = document.querySelector('.dropdown-list');
        const listHeight = dropdownElement?.offsetHeight || 200; // fallback para 200 se não encontrar

        setDropdownStyles((prev) => ({
          ...prev,
          top: shouldOpenAbove
            ? rect.top + window.scrollY - listHeight
            : rect.bottom + window.scrollY,
        }));
      }, 0);

      setOpenAbove(shouldOpenAbove);
    }
  };

  const handleFocus = () => {
    let shouldOpenAbove = false;
    if (shouldOpenUp) {
      shouldOpenAbove = isDivOutOfViewport(dropdownRef.current);
      setOpenAbove(shouldOpenAbove);
    }
    handleOpen(shouldOpenAbove);
  };

  const handleClickOutside = (event) => {
    // Verifica se o clique foi fora do dropdown ou do container
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      listRef.current &&
      !listRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className='inputContainer' style={style}>
      <div className='select-container' tabIndex={0} ref={dropdownRef} onFocus={handleFocus}>
        <div className='inputIconContainer' style={{ right: 0 }}>
          <img src={arrowDown} alt='Arrow Down' />
        </div>
        <div className={`customInput ${disabled ? 'disabled' : ''}`}>
          <div style={{ height: '100%', width: '90%', overflow: 'hidden' }}>
            {options.find((op) => op.id === selected)?.label ?? t('choose')}
          </div>
        </div>
        {isOpen &&
          createPortal(
            <ul
              ref={listRef}
              className={`dropdown-list ${openAbove ? 'openAbove' : ''}`}
              style={{
                position: 'absolute',
                top: `${dropdownStyles.top}px`,
                left: `${dropdownStyles.left}px`,
                width: `${dropdownStyles.width}px`,
              }}
            >
              {options.map((option, index) => (
                <li
                  key={index}
                  className={selected === option.id ? 'selected' : ''}
                  onClick={() => handleSingleSelectBehaviour(option.id)}
                >
                  {option.label}
                </li>
              ))}
            </ul>,
            document.body,
          )}
      </div>
    </div>
  );
};

export default SingleSelect;
