import React, { useState, useMemo } from 'react';

import filterImg from '../../../images/filter.svg';
import filterSelected from '../../../images/filterSelected.svg';

import TableCellFilterWrapper from './FilterWrapper';
import SearchFilter from './searchFilter';
import ListFilter from './llistFilter';

const Filter = ({
  column,
  filter,
  setFilter,
  triggerApiCall,
  tableSettings,
  tableLoading,
  index,
}) => {
  const [showFilter, setShowFilter] = useState(null);
  const [filterPosition, setFilterPosition] = useState(null);

  // Função para verificar se o filtro está ativo
  const isFilterActive = useMemo(() => {
    if (column?.filter?.getFilter) {
      // Obter as chaves que o getFilter gera usando um valor de teste
      const testFilter = column.filter.getFilter('test');
      return Object.keys(testFilter).every(
        (key) => filter[key] !== undefined && filter[key] !== null,
      );
    }

    if (column?.filter?.type === 'search') {
      // Para filtro de busca, verifica se existe alguma chave que começa com columnId[regex]
      const regexKey = `${column.id}[regex]`;
      return filter[regexKey] !== undefined && filter[regexKey] !== null && filter[regexKey] !== '';
    }

    // Para filtro de lista, verifica se existe o valor direto
    return (
      filter[column.id] !== undefined && filter[column.id] !== null && filter[column.id] !== ''
    );
  }, [column, filter]);

  const handleOpenFilter = (event) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setFilterPosition({
      top: rect.bottom + window.scrollY,
      left: rect.left + window.scrollX,
    });
  };

  return (
    <div
      key={`opwellTableCellControll-column${index}-filter`}
      id={`opwellTableCellControllFilter-${column.id}`}
      className='opwellTableCellControll filter'
      onClick={(e) => {
        if (!tableLoading) {
          setShowFilter(column.id);
          handleOpenFilter(e);
        }
      }}
    >
      <img src={showFilter === column.id || isFilterActive ? filterSelected : filterImg} />
      <TableCellFilterWrapper
        showFilter={showFilter}
        isOpen={!!filterPosition}
        position={filterPosition}
        name={column.id}
        onClose={() => {
          setShowFilter(null);
          setFilterPosition(null);
        }}
      >
        {column?.filter?.type === 'search' ? (
          <SearchFilter
            column={column}
            filter={filter} // Adicionar esta prop
            setFilter={setFilter}
            setShowFilter={setShowFilter}
            setFilterPosition={setFilterPosition}
            triggerApiCall={triggerApiCall}
          />
        ) : (
          <ListFilter
            column={column}
            filter={filter}
            setFilter={setFilter}
            setShowFilter={setShowFilter}
            setFilterPosition={setFilterPosition}
            triggerApiCall={triggerApiCall}
            tableSettings={tableSettings}
          />
        )}
      </TableCellFilterWrapper>
    </div>
  );
};

export default Filter;
