const TableTip = ({ children }) => {
  const status = {
    default: ['scheduled'],
    ongoing: ['active', 'ongoing', 'ongoing approval'],
    success: ['success', 'validated', 'completed', 'approved', 'yes'],
    alert: ['expiring', 'pending', 'incomplete', 'pending approval'],
    fail: ['expired', 'fail', 'rejected', 'no'],
  };

  return (
    <div className='tableTipContainer'>
      <div
        className={`tableTip ${
          Object.entries(status).find(([key, value]) =>
            value.includes(children?.toLowerCase()),
          )?.[0] ?? ''
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default TableTip;
