import axios from 'axios';
import { acquireToken } from '../../auth/msal.js';
import { protectedResourcesScopes } from '../../auth/config.js';

const apiUrl = `${process.env.REACT_APP_BFF_API}`;
const scope = protectedResourcesScopes.core;

export const generateKnowledge = async (instance, initialInput, currentEquipmentId) => {
  try {
    const accessToken = await acquireToken(instance, scope);

    const { data } = await axios.post(
      `${apiUrl}/virtual-engineer/documentGenerator/create`,
      {
        initialInput: initialInput,
        subject_id: currentEquipmentId,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return data;
  } catch (error) {
    console.error('Error generating knowledge:', error);
    throw error;
  }
};

export const regenerateKnowledge = async (instance, generatorId, input) => {
  try {
    const accessToken = await acquireToken(instance, scope);

    const { data } = await axios.post(
      `${apiUrl}/virtual-engineer/documentGenerator/generate`,
      {
        generatorId,
        input,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return data;
  } catch (error) {
    console.error('Error regenerating knowledge:', error);
    throw error;
  }
};

export const registerKnowledge = async (instance, generatorId, prompt, result, assets, user) => {
  try {
    const accessToken = await acquireToken(instance, scope);

    const { data } = await axios.post(
      `${apiUrl}/virtual-engineer/documentGenerator/knowledge`,
      {
        generatorId,
        prompt,
        result,
        assets,
        user,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const confirmKnowledgeAddition = async (instance, generatorId, content, assets) => {
  try {
    const accessToken = await acquireToken(instance, scope);

    const { data } = await axios.post(
      `${apiUrl}/virtual-engineer/documentGenerator/save`,
      {
        generatorId,
        content,
        assets,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return data;
  } catch (error) {
    console.error('Error confirming knowledge addition:', error);
    throw error;
  }
};
