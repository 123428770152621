import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';

import back from '../../../../../../../../images/back.svg';
import { Button, notification } from 'antd';
import ReportForms from './ReportForms';
import { useState } from 'react';
import { useVersionControl } from './ReportForms/hooks/useVersionControl';
import VersionSelector from './ReportForms/components/VersionSelector';

const ReportPage = ({ setReportOpen, report, setReport, setForceReload }) => {
  const { t } = useTranslation();

  const [notificationApi, contextHolder] = notification.useNotification();

  const [editing, setEditing] = useState(false);
  const [originalReport, setOriginalReport] = useState(null);
  const [selectedVersion, setSelectedVersion] = useState(1);

  const { instance } = useMsal();

  const account = instance.getActiveAccount();

  const { given_name: givenName, family_name: familyName, name } = account.idTokenClaims || {};
  const currentUser = name ?? `${givenName} ${familyName}`;

  const {
    handleSave,
    handleCancel,
    handleVersionChange,
    handlePhaseValueChange,
    getVersionContent,
  } = useVersionControl({
    report,
    setReport,
    originalReport,
    selectedVersion,
    setSelectedVersion,
    notificationApi,
    currentUser,
    setForceReload,
  });

  useEffect(() => {
    setSelectedVersion(report.report.latestVersion);
  }, [report]);

  useEffect(() => {
    if (editing) {
      setOriginalReport(JSON.parse(JSON.stringify(report)));
    }
  }, [editing]);

  /**
   * Determina se a edição do relatório deve ser desabilitada
   *
   * A edição é desabilitada nas seguintes situações:
   * 1. Quando uma versão anterior está selecionada (selectedVersion !== report.report.latestVersion)
   *    - Apenas a versão atual (latestVersion) pode ser editada
   *    - Versões anteriores são somente leitura
   *
   * 2. Quando o status do relatório não é 'Pending Approval'
   *    - Relatórios aprovados não podem ser editados
   *    - Relatórios reprovados não podem ser editados
   *    - Apenas relatórios pendentes de aprovação podem ser modificados
   *
   * 3. Quando o relatório possui algum checklist errado
   *    - Apenas relatórios com erro na validação da imagem podem ser editados
   *
   * @returns {boolean} true se a edição deve ser desabilitada, false caso contrário
   */
  function disableEditReport() {
    return (
      selectedVersion !== report.report.latestVersion ||
      report.report.status !== 'Pending Approval' ||
      report.phase.some(
        (phase) => phase.type === 'form' && phase.fields.some((field) => !field.isGoodToGo),
      )
    );
  }

  return (
    <div className='reportContainer'>
      {contextHolder}
      <div className='reportHeader'>
        <div
          className='reportTitleContainer'
          onClick={() => {
            if (!editing) setReportOpen(false);
          }}
          style={{ cursor: editing ? 'not-allowed' : '', opacity: editing ? 0.5 : 1 }}
        >
          <div className='backArrow'>
            <img src={back} />
          </div>
          <div className='title'>{t('back')}</div>
        </div>
        <div className='controls'>
          {!editing && (
            <VersionSelector
              versions={report.report.versions}
              currentVersion={selectedVersion}
              onVersionChange={({ target }) => handleVersionChange(target.value)}
            />
          )}
          {editing && (
            <Button
              size='large'
              style={{
                borderColor: '#333333',
                background: '#333333',
                color: 'white',
              }}
              onClick={() => {
                setEditing(false);
                handleCancel();
              }}
            >
              {t('cancel-btn')}
            </Button>
          )}
          <Button
            size='large'
            style={{
              borderColor: disableEditReport() ? 'white' : '#1777FF',
              background: disableEditReport() ? 'grey' : '#1777FF',
              color: 'white',
            }}
            disabled={disableEditReport()}
            onClick={() => {
              if (editing) {
                handleSave();
              }
              setEditing(!editing);
            }}
          >
            {editing ? t('save') : t('edit-btn')}
          </Button>
        </div>
      </div>
      <ReportForms
        editing={editing}
        report={report}
        handlePhaseValueChange={handlePhaseValueChange}
        getVersionContent={getVersionContent}
      />
    </div>
  );
};

export default ReportPage;
