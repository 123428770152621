import { useEffect, useState } from 'react';
import PageWithScrollableSider from '../../../../Template/layoutComponents/PageWithScrollableSider';
import { getKnowledge, updateKnowledge } from '../../../helpers/apiCalls';
import useTemplateState from '../../../../Template/atoms/variables';
import { Button, notification } from 'antd';
import { useMsal } from '@azure/msal-react';
import Markdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import { CloseCircleOutlined, EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { parseIsoDateString } from '../../../../helpers/misc';

import AffectedSubjects from './components/AffectedSubjects';
import nothingSelectedImg from '../../../images/noItemSelected.svg';
import './style.css';

const ManageKnowledge = () => {
  const { setPendingApiCalls } = useTemplateState();
  const [notificationApi, contextHolder] = notification.useNotification();
  const { instance } = useMsal();

  const [knowledge, setKnowledge] = useState({ items: [], totalItems: 0 });

  const [chosenItem, setChosenItem] = useState(null);

  useEffect(() => {
    triggerApiCalls();
  }, []);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['getKnowledge']));

      const response = await getKnowledge(instance, { status: 'Pending' });
      setKnowledge(response);
    } catch (err) {
      notificationApi.error({
        message: 'Falha ao carregar hierarquia',
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
      console.log(err);
    } finally {
      setPendingApiCalls((current) => current.filter((item) => item !== 'getKnowledge'));
    }
  };

  const handleItemClick = (item, index) => {
    setChosenItem(item?.props?.knowledgeEntry);
  };

  return (
    <>
      {contextHolder}
      <PageWithScrollableSider
        siderItems={knowledge?.items.map((entry) => (
          <KnowledgeItem
            knowledgeEntry={entry}
            className={chosenItem?._id === entry?._id ? 'selected' : null}
          />
        ))}
        defaultSelectedItem={null}
        onItemClick={handleItemClick}
        pageContent={
          <PageContent knowledge={knowledge} setKnowledge={setKnowledge} chosenItem={chosenItem} />
        }
      />
    </>
  );
};

const KnowledgeItem = ({ knowledgeEntry, className }) => {
  const { t } = useTranslation();

  return (
    <div className={`knowledgeItem ${className ?? ''}`}>
      <div className='title'>{knowledgeEntry.prompt}</div>
      <div className='subtitle'>
        {knowledgeEntry?.subject?.length > 1
          ? knowledgeEntry?.subject?.length > 2
            ? `${knowledgeEntry?.subject[0]?.name} (+${knowledgeEntry?.subject?.length - 1} ${t('others')})`
            : `${knowledgeEntry?.subject[0]?.name} and ${knowledgeEntry?.subject[1]?.name}`
          : (knowledgeEntry?.subject[0]?.name ?? '')}
      </div>
      <div className='info'>
        <div className='user'>{knowledgeEntry.user}</div>
        <div className='updatedAt'>{parseIsoDateString(knowledgeEntry?.updatedAt)}</div>
      </div>
    </div>
  );
};

const PageContent = ({ knowledge, setKnowledge, chosenItem }) => {
  const { t } = useTranslation();
  const { instance } = useMsal();
  const [notificationApi, contextHolder] = notification.useNotification();

  const {
    setModalOpen,
    setModalStyle,
    setModalTitle,
    setModalConfirm,
    setModalContent,
    setPendingApiCalls,
  } = useTemplateState();

  const [tmpValue, setTmpValue] = useState('');
  const [finalValue, setFinalValue] = useState('');
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    setTmpValue(chosenItem?.knowledge ?? '');
    setFinalValue(chosenItem?.knowledge ?? '');
  }, [chosenItem]);

  const handleInfoClick = (chosenItem) => {
    setModalTitle(t('affected_subjects'));
    setModalStyle({ padding: '2rem', gap: '1rem', minHeight: '70%', minWidth: '55%' });
    setModalContent(
      <AffectedSubjects subjects={chosenItem?.subject} type={chosenItem?.subject_kind} />,
    );
    setModalConfirm(null);
    setModalOpen(true);
  };

  const handleTextAreaChange = (e) => {
    setTmpValue(e.target.value);
  };

  const handleEditClick = () => {
    setEditing(true);
  };

  const handleCancelClick = () => {
    setEditing(false);
    setTmpValue(finalValue);
  };

  const handleSaveClick = () => {
    setFinalValue(tmpValue);
    setKnowledge((current) => ({
      ...current,
      items: current.items.map((item) => {
        if (chosenItem?._id === item?._id) {
          return { ...item, knowledge: tmpValue };
        }
        return item;
      }),
    }));
    setEditing(false);
  };

  const handleFinalClick = async (chosenItem, status = 'Approved') => {
    try {
      setPendingApiCalls((current) => current.concat(['updateKnowledge']));

      await updateKnowledge(instance, { ...chosenItem, status: status });

      setKnowledge((current) => ({
        ...current,
        items: current.items.filter((item) => item._id !== chosenItem?._id),
      }));
    } catch (err) {
      notificationApi.error({
        message: 'Falha ao carregar hierarquia',
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
      console.log(err);
    } finally {
      setPendingApiCalls((current) => current.filter((item) => item !== 'updateKnowledge'));
    }
  };

  return !chosenItem ? (
    <div className='nothingSelected'>
      <img className='nothingSelectedImage' src={nothingSelectedImg} />
      <div className='nothingSelectedText'>
        <div className='title'>{t('nothing_selected')}</div>
        <div className='subtitle'>
          {knowledge?.items?.length === 0 ? t('no_items') : t('select_to_read')}
        </div>
      </div>
    </div>
  ) : (
    <>
      {contextHolder}
      <div className='knowledgeInfos'>
        <div className='subjectAndUser'>
          <div
            className={`subjectContainer ${chosenItem ? '' : 'disabled'}`}
            onClick={() => {
              if (chosenItem) handleInfoClick(chosenItem);
            }}
          >
            <div className='subject'>{t('affected_subjects')}</div>
            <InfoCircleOutlined className='infoCircle' style={{ fontSize: '1.2rem' }} />
          </div>
          /<div className='userContainer'>{`${t('responsible')}: ${chosenItem?.user ?? '-'}`}</div>
        </div>
        <div className='date'>{`${t('date')}: ${parseIsoDateString(chosenItem?.updatedAt ?? '')}`}</div>
      </div>
      {editing ? (
        <textarea className='addKnowledgeTextArea' onChange={handleTextAreaChange}>
          {finalValue}
        </textarea>
      ) : (
        <div className='addKnowledgeTextArea md'>
          <Markdown>{finalValue}</Markdown>
        </div>
      )}
      <div className='buttonsContainer'>
        {editing ? (
          <Button
            id='cancel'
            size='large'
            style={{
              backgroundColor: 'white',
              borderColor: 'black',
              color: 'black',
            }}
            onClick={handleCancelClick}
          >
            {t('cancel-btn')}
          </Button>
        ) : (
          <Button
            icon={<EditOutlined />}
            size='large'
            style={{
              backgroundColor: '#333333',
              border: '#333333',
              color: 'white',
              opacity: chosenItem ? 1 : 0.5,
            }}
            onClick={handleEditClick}
            disabled={!chosenItem}
          >
            {t('edit-btn')}
          </Button>
        )}
        <div style={{ display: 'flex', gap: '.5rem' }}>
          {!editing && (
            <Button
              id='cancel'
              size='large'
              style={{
                backgroundColor: 'white',
                border: '#FF4D4FF',
                color: '#FF4D4F',
                opacity: tmpValue.length === 0 ? 0.5 : 1,
              }}
              icon={<CloseCircleOutlined />}
              disabled={tmpValue.length === 0}
              onClick={() => handleFinalClick(chosenItem, 'Rejected')}
            >
              {t('reject')}
            </Button>
          )}
          <Button
            size='large'
            style={{
              backgroundColor: '#1777FF',
              border: '#1777FF',
              color: 'white',
              opacity: tmpValue.length === 0 ? 0.5 : 1,
            }}
            disabled={tmpValue.length === 0}
            onClick={() => (editing ? handleSaveClick() : handleFinalClick(chosenItem, 'Approved'))}
          >
            {editing ? t('save_btn') : t('add_knowledge_button')}
          </Button>
        </div>
      </div>
    </>
  );
};

export default ManageKnowledge;
