import React from 'react';

import appstore from '../../../../../images/appstore.svg';
import appstoreSelected from '../../../../../images/appstoreSelected.svg';

const AppstoreIconComponent = ({ height = '14px', width = height, altText = 'icon', selected }) => {
  return (
    <img src={selected ? appstoreSelected : appstore} alt={altText} style={{ width, height }} />
  );
};

export default AppstoreIconComponent;
