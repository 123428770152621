import { Button, notification } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import Table from '../../../../../../Template/layoutComponents/Table';
import useTemplateState from '../../../../../../Template/atoms/variables';
import { useMsal } from '@azure/msal-react';
import {
  getAssetCategories,
  getAssets,
  getManufacturers,
  getPlants,
} from '../../../../../../Maintenance/helpers/apiCalls';
import { useEffect, useState } from 'react';
import useVirtualEngineerState from '../../../../../atoms/variables';
import { useParams } from 'react-router-dom';
import SuccessModal from './SuccessModal';
import { registerKnowledge } from '../../../../../helpers/conversation';

const TableModal = ({ generatorId, prompt, result }) => {
  const [notificationApi, contextHolder] = notification.useNotification();
  const { currentEquipment } = useVirtualEngineerState();

  const { equipmentId } = useParams();

  const { t } = useTranslation();
  const {
    setPendingApiCalls,
    setModalOpen,
    setModalContent,
    setModalStyle,
    setCleanOnCancel,
    setModalTitle,
    setModalConfirm,
    user,
  } = useTemplateState();
  const { instance } = useMsal();

  const [assets, setAssets] = useState({ items: [], totalItems: 0 });
  const [allCategories, setAllCategories] = useState([]);
  const [plants, setPlants] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);

  const [forceReload, setForceReload] = useState([]);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    triggerApiCalls();
  }, []);

  useEffect(() => {
    setForceReload(true);
  }, assets);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) =>
        current.concat(['getAssets', 'getAssetCategories', 'getPlants', 'getManufacturers']),
      );

      const responses = await Promise.all([
        getAssets(instance, { limit: 10 }),
        getAssetCategories(instance),
        getPlants(instance),
        getManufacturers(instance),
      ]);

      setAssets(responses[0]);
      setAllCategories(responses[1]?.items ?? []);
      setPlants(responses[2]?.items ?? []);
      setManufacturers(responses[3]);
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
      console.log(err);
    } finally {
      setPendingApiCalls((current) =>
        current.filter(
          (item) =>
            item !== 'getAssets' &&
            item !== 'getAssetCategories' &&
            item !== 'getPlants' &&
            item !== 'getManufacturers',
        ),
      );
    }
  };

  const showSuccessModal = () => {
    setModalTitle('');
    setModalContent(<SuccessModal />);
    setModalStyle({ padding: '2rem', gap: '1rem', minHeight: '60%', minWidth: '40%' });
    setModalConfirm(null);
    setModalOpen(true);

    setTimeout(() => setModalOpen(false), 2000);
  };

  const handleGenerateKnowledge = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['registerKnowledge']));
      await registerKnowledge(instance, generatorId, prompt, result, selected, user);
      showSuccessModal();
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
    } finally {
      setPendingApiCalls((current) => current.filter((item) => item !== 'registerKnowledge'));
    }
  };

  return (
    <div className='addKnowledgeModalContent'>
      {contextHolder}
      <div className='addKnowledgeDescription'>{t('asset_selection_description')}</div>
      <div className='addKnowledgeDescription'>
        <Trans
          i18nKey='asset_preselected'
          values={{ assetName: currentEquipment?.name }}
          components={{ strong: <strong /> }}
        />
      </div>
      <Table
        handleSelect={(selected) => {
          setSelected(selected);
        }}
        startingSelected={[{ id: equipmentId, _id: equipmentId }]}
        handleRowClick={null}
        apiCall={(instance, query) => getAssets(instance, { ...query })}
        items={assets?.items}
        tableSettings={{
          totalItems: assets.totalItems ?? 0,
          columnsConfig: {
            name: {
              label: t('name'),
            },
            category: {
              label: t('category'),
              replaceValue: (value) =>
                allCategories?.find((cat) => cat._id === value || cat.id === value),
            },
            manufacturer: {
              label: t('manufacturer'),
              replaceValue: (value) =>
                manufacturers?.find((cat) => cat._id === value || cat.id === value),
            },
            parent: {
              label: t('plant'),
              replaceValue: (value) => plants?.find((cat) => cat._id === value || cat.id === value),
            },
          },
          sortable: ['category', 'name', 'parent', 'manufacturer'],
          listFilter: ['category', 'parent', 'manufacturer'],
          searchFilter: ['name'],
          uniqueValues: assets.uniqueValues,
        }}
      />
      <Button
        size='large'
        disabled={selected.length === 0}
        style={{
          alignSelf: 'end',
          backgroundColor: '#1777FF',
          border: '#1777FF',
          color: 'white',
          opacity: selected.length === 0 ? 0.5 : 1,
        }}
        onClick={handleGenerateKnowledge}
      >
        {t('add_knowledge')}
      </Button>
    </div>
  );
};

export default TableModal;
